import React, { useState, useEffect,useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import constant from "../../../../constants/constant"
import {Default_Page_Limit} from "../../../../constants/defaultValues"
import organisms from '../../../../components/organisms'
import { HeaderHeading } from '../../../../Context'

const { TransferRequestCancelled } = organisms

const Index = () => {
  const [initialLoading, setInitialLoading]=useState(false)
  const [transferRequestCancelledList, setTransferRequestCancelledList] = useState<any>();
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();
  const { setHeader }: any = useContext(HeaderHeading);

  const getTransferRequestCancelledList = async () => {
    try {
      const response = await service.procurementService.transferRequestCancelledList({ "page": currentPage, "search": search, "limit": limitPerPage, "status": constant.PROCUREMENT_ITEM_STATUS.CANCEL })
      if (response.status === 200) {
        setTransferRequestCancelledList(response?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setHeader("Transfer Requests - Cancelled")
    if(initialLoading){
      getTransferRequestCancelledList()
    }
    setInitialLoading(true)
  }, [search,currentPage])

  useEffect(() => {
    setCurrentPage(1)
    if (currentPage == 1) {
      getTransferRequestCancelledList()
    }
}, [limitPerPage])

  return (
    <Wrapper>
     <TransferRequestCancelled
       transferRequestCancelledList={transferRequestCancelledList}
       setSearch={setSearch}
       limit={limitPerPage}
       currentPage={currentPage}
       setCurrentPage={setCurrentPage}
       setLimitPerPage={setLimitPerPage}
       />
    </Wrapper>
  )
}

export default Index