import React from 'react';
import './Badges.scss'
import { BudgesProps } from './interface';

const Image = ({ count, style, className }: BudgesProps) => {
    return (
        <>
            {(count && count > 0) ? <span className={`${!className && "translate"} ${className} bg-danger badge rounded-pill fs-10`} style={style}>
                {`${count}`}
            </span> : ""}
        </>
    );
};

export default Image;
