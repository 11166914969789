import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import BrandPlaceHolder from '../../../assets/Images/placeholderimg.png';
import { useNavigate } from 'react-router';
import service from '../../../api/services';
import { HeaderHeading } from '../../../Context';
import organisms from '../../../components/organisms';

const { Dashboard } = organisms

const Index = () => {
  const { setHeader }: any = useContext(HeaderHeading);
  const navigate = useNavigate()
  const [dashboardDetails, setDashboardDetails] = useState()
  const getDashboardDetails = async () => {
    try {
      const response = await service.DashboardService.dashboard()
      if (response.status === 200) {
        setDashboardDetails(response.data.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    setHeader("Dashboard")
    getDashboardDetails()
  }, [])
  return (
    <Wrapper>
      {/* <div className="d-flex flex-row flex-wrap gap-3">
        <div className="">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='bg-dark text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Top Brands</h2>
          </div>
        </div>

        <div className="">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='bg-dark text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Stock List</h2>
          </div>
        </div>

        <div className="">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='bg-dark text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Master Stock List</h2>
          </div>
        </div>


        <div className="">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='bg-dark text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Order Completed </h2>
          </div>
        </div>

        <div className="">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='bg-dark text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Sales History </h2>
          </div>
        </div>

        <div className="">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='bg-dark text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Pending Orders  </h2>
          </div>
        </div>

        <div className="">
          <div className='dashboard'>
            <img src={BrandPlaceHolder} alt="" className='img-fluid w-100' />

          </div>
          <div className='bg-dark text-center '>
            <h2 className='text-white fs-20 fw-400 mb-0 py-2'>Warranty</h2>
          </div>
        </div>




      </div> */}
      {dashboardDetails && <Dashboard
        dashboardDetails={dashboardDetails}
      />}
    </Wrapper>
  )
}

export default Index