import React from 'react'
import PlaceHolderImage from '../../../../assets/Images/placeholderimg.png'
import { convertJsonToExcel, numberWithCommas } from "../../../../constants/utils";
import constant from '../../../../constants/constant';
import moment from 'moment'
import { MyComponentProps } from './interface'
import atoms from '../../../atoms';

const { Pagination, Search, Table, Input, Button } = atoms

const Index = ({ orderReceivedList, setSearch, currentPage, setCurrentPage, setLimitPerPage,
    limit, setCreatedFrom, setCreatedTo }: MyComponentProps) => {

    const columns = [
        { name: 'Product ID', selector: (row: any) => row?.product?.product_id_display, },
        { name: 'Brand', selector: (row: any) => row?.product?.brand?.brand_name || '-', },
        { name: 'Cover Photo', selector: (row: any) => <img src={row?.product?.images[0] ? row?.product?.images[0] : PlaceHolderImage} className='w-50 rounded object-contain' /> },
        { name: 'Model No.', selector: (row: any) => row?.product?.modal_number || '-', },
        { name: 'Color Code', selector: (row: any) => row?.product?.brand_color_code || '-', },
        { name: 'SRP', selector: (row: any) =>`₹${numberWithCommas(parseFloat(Number(row?.product?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Landing Cost', selector: (row: any) =>`₹${numberWithCommas(parseFloat(Number(row?.landing_cost)?.toFixed(2)))}` || '-', },
        { name: 'With Case', selector: (row: any) => row?.product?.product_with_case ? 'Yes' : 'No' || '-', },
        { name: 'Date', selector: (row: any) => moment(row?.createdAt).format('DD/MM/YYYY , H:mm:ss') || '-', },
        { name: 'Delivery Note No.', selector: (row: any) => row?.delivery_note_num || '-', },

    ]

    const handleDownloadExcel = () => {
        if (orderReceivedList?.data?.length > 0) {
            const excelData = orderReceivedList?.data?.map((item: any) => {
                const rowData: any = {};
                columns.forEach((column: any) => {
                    if (column.name != "Cover Photo") {
                        rowData[column.name] = column?.selector(item);
                    }
                });
                return rowData;
            });

            convertJsonToExcel(excelData, "Inventory_excel")
        }
    }

    return (
        <>
            {/* <div className="row align-items-center">
                <div className="col-md-4 mb-md-0 mb-3">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} className="w-md-50 w-100" />
                </div>
                <div className="col-md-8">
                    <div className="d-md-flex gap-4 align-items-center justify-content-end">
                        <div className="d-md-flex mb-md-0 mb-3 align-items-center gap-2">
                            <label className='inputs-label mb-md-auto mb-2 fs-16 fw-600 my-auto secondary-color'>From</label>
                            <Input
                                type={'Date'}
                                className="input-height"
                                placeholder={"Enter End Date"}
                                onChange={(e: any) => {
                                    setCreatedFrom(e.target.value)
                                }} />
                        </div>
                        <div className="d-md-flex mb-md-0 mb-3 align-items-center gap-md-2 gap-4">
                            <label className='inputs-label mb-md-auto mb-2 fs-16 fw-600 my-auto secondary-color'>To</label>
                            <Input
                                type={'Date'}
                                className="input-height"
                                placeholder={"Enter Start Date"}
                                onChange={(e: any) => {
                                    setCreatedTo(e.target.value)
                                }} />
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button>Download</Button>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='row'>
                <div className='col-12 col-md-4 mb-lg-0 mb-3'>
                    <Search searchCallBack={(e: string) => { setSearch(e) }} className="w-100" />
                </div>
                <div className='col-12 col-md-8 gap-2 d-lg-flex justify-content-lg-end px-lg-0'>
                    <div className='row ps-lg-3 justify-content-end'>
                        <div className="col-6 d-md-flex mb-lg-0 mb-2 align-items-center gap-2">
                            <label className='inputs-label fs-16 fw-600 secondary-color'>From</label>
                            <div className='w-100'>
                                <Input
                                    type={'Date'}
                                    className="input-height change-min-height"
                                    placeholder={"Enter End Date"}
                                    onChange={(e: any) => {
                                        setCreatedFrom(e.target.value)
                                    }} />
                            </div>
                        </div>
                        <div className="col-6 d-md-flex mb-lg-0 mb-2 align-items-center gap-2">
                            <label className='inputs-label fs-16 fw-600 secondary-color'>To</label>
                            <div className='w-100'>
                                <Input
                                    type={'Date'}
                                    className="input-height change-min-height"
                                    placeholder={"Enter Start Date"}
                                    onChange={(e: any) => {
                                        setCreatedTo(e.target.value)
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-lg-center justify-content-end'>
                        <Button onClick={() => handleDownloadExcel()}>Download</Button>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    columns={columns}
                    AdditionalClassName="table-height-50"
                    data={orderReceivedList?.data}
                />
            </div>
            <div>
                <Pagination
                    totalCount={orderReceivedList?.count}
                    currentPage={currentPage}
                    totalPages={orderReceivedList?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
        </>
    )
}

export default Index