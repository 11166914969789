import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import atoms from '../../atoms'
const { Button } = atoms

const ConfirmationModal = (props: any) => {
    return (
        <>
            <Modal className="h-100 d-flex justify-content-center align-items-center modal-m" isOpen={props.show}>
                {/* <ModalHeader >Confirmation</ModalHeader> */}
                <ModalBody className="rounded-10">
                    {props.message && <h5 className='text-center' style={{marginTop: "25px"}}>Are you sure you want to {props.message}?</h5>}
                    <div className='text-center' style={{marginTop: "25px"}}>
                        <Button className='rounded-2 px-4 table-delete-button'
                            onClick={props.onHide}>No</Button>
                        <Button className='rounded-2 ms-3 px-4 table-edit-button'
                            onClick={props.handleConfirmItem}>Yes</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>



    )
}

export default ConfirmationModal