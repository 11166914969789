import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { settingTab } = Dictionary

const SettingService = {
    getList: async () => {
        return axios.get(BASE_URL + settingTab.get())
    },
    updateSetting: async () => {
        return axios.put(BASE_URL + settingTab.update())
    }

}
export default SettingService