import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { HeaderHeading } from '../../../../Context'
import { useParams } from 'react-router'

const { OrderHistoryItems } = organisms

const Index = () => {
  const [orderHistoryItems, setOrderHistoryItems] = useState<any>()
  const { setHeader }: any = useContext(HeaderHeading);
  const params = useParams()

  useEffect(() => {
    setHeader("")
    getOrderHistoryItemDetails()
  }, [])

  const getOrderHistoryItemDetails = async () => {
    try {
      const response = await service.procurementService.orderHistoryItems(params?.id || '')
      if (response.status == 200) {
        setOrderHistoryItems(response?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const cancelButton = async (id: string) => {
    try {
      const response = await service.procurementService.orderHistoryItemCancel(id, "")
      if (response.status == 200) {
        getOrderHistoryItemDetails()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Wrapper>
      <OrderHistoryItems orderHistoryItems={orderHistoryItems} cancelButton={(id: string) => cancelButton(id)} />
    </Wrapper>
  )
}

export default Index