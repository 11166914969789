import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { brand } = Dictionary

const brandService = {
    brandList: async (paramsObj:{}) => {
        return axios.get(BASE_URL + brand?.BrandList(), { params: paramsObj })
    }
}
export default brandService
