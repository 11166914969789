import React, { useState, useEffect, useContext } from "react";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from "react-toast-notifications";
import service from "../../../../api/services";
import { BASE_URL } from "../../../../constants/defaultValues";
import { HeaderHeading } from "../../../../Context";
import * as XLSX from "xlsx"
import constant from "../../../../constants/constant";
import { generatePdfHeader } from '../../../../constants/utils'

const { GSTOutReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();
    const [reportDate, setReportDate] = useState({
        created_from: Date,
        created_to: Date,
        report_format: NaN
    })

    useEffect(() => {
        setHeader("Reports - GST Output Report")
    }, [])

    const handleChange = (name: string, value: Date) => {
        setReportDate({ ...reportDate, [name]: value })
    }

    const handleSubmit = async (type: string) => {

        try {
            const response = await service.reportService.gstOutput({ created_from: reportDate?.created_from, created_to: reportDate?.created_to })
            if (response.status === 200) {
                let path = response.data.data
                let fullPath = `${BASE_URL}${path}`
                let cleanPAth = fullPath.replace(/api\/\.\//, '')
                let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
                // window.open(`${cleanPAth}`, '_blank')

                setTimeout(() => {
                    fetch(`${cleanPAth}`).then((response) => {
                        response.blob().then((blob) => {

                            // Creating new object of PDF file
                            const fileURL =
                                window.URL.createObjectURL(blob);

                            // Setting various property values
                            let alink = document.createElement("a");
                            alink.href = fileURL;
                            alink.download = `${fileName}`;
                            alink.click();
                        });
                    });
                }, 2000);

            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    return (
        <Wrapper>
            <GSTOutReport
                reportDate={reportDate}
                handleChange={(name: string, value: Date) => handleChange(name, value)}
                handleSubmit={(type: string) => handleSubmit(type)} />
        </Wrapper>
    )
}

export default Index