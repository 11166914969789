import React, { useEffect, useState } from "react";
import moment from "moment";
import atoms from "../../atoms";
import molecules from "../../molecules";
import { MyComponentProps } from './interface'
const { Table, Search, Input, Button } = atoms
const { CustomTable } = molecules

const Index = ({ storeBrandList, searchCallBack, setStoreBrandList, search }: MyComponentProps) => {
    const [searchData, setSearchData] = useState<any>()

    const filterData = (data: any, searchData: string | undefined) => {
        if (search) {

            return setSearchData(data?.data?.brands.filter((store: any) => {
                return ((store?.brand_name?.toLowerCase().includes(searchData?.toLowerCase())));
            }))
        } else {
            setSearchData(storeBrandList?.data?.brands)
        }
    };


    useEffect(() => {
        filterData(storeBrandList, search)
    }, [storeBrandList,search])

    return (
        <>
            <div className="row mb-3">
                <div className="col-md-6"><Search searchCallBack={(e: string) => { searchCallBack(e) }} className="w-100" /></div>
            </div>
            {storeBrandList?.data?.brands?.length > 0 ?
                <>
                    <div  className="brand-container-style hide-scroll">
                        <CustomTable storeBrandList={searchData}/>
                    </div>
                </> : <div className='justify-content-center d-flex mt-5 '>There are no records to display</div>
            }
        </>
    )
}

export default Index