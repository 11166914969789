import { Navigate, Route, Routes } from 'react-router';
import { PublicRoutes, privateRoutes } from './routes';
import NonAuthLayout from './layout/NonAuthLayout';
import AuthLayout from './layout/AuthLayout';
import Interceptor from './api/interceptor'
import Error404 from './pages/App/Error404'
import React, { useState } from 'react';
import { Context, NotificationCount, HeaderHeading, StorePopup, SelectedCheckboxes } from './Context';
const App = () => {
  const [context, setContext] = useState<string[]>();
  const [notificationCount, setNotificationCount] = useState<any>();
  const [header, setHeader] = useState<any>('');
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<{ [key: string]: string[] }>({
    brand: [],
    type: [],
    polarized: [],
    frame_materials: [],
    product_shapes: [],
    rim_type: [],
    features: [],
    gender: [],
    price: [],
    sw_color_list: [],
    lens_color: [],
    bridge_size: [],
    weight: [],
    glass_size: [],
    temple_size: [],
  });

  return (
    <React.Fragment>
      <SelectedCheckboxes.Provider value={{ selectedCheckboxes, setSelectedCheckboxes }}>
        <StorePopup.Provider value={[isShow, setIsShow]}>
          <Context.Provider value={[context, setContext]}>
            <NotificationCount.Provider value={[notificationCount, setNotificationCount]}>
              <HeaderHeading.Provider value={{ header, setHeader }}>
                <Interceptor />
                <Routes >
                  {PublicRoutes.map((route, idx) => (
                    <Route
                      path={route.path}
                      element={
                        <NonAuthLayout>
                          {route.component}
                        </NonAuthLayout>
                      }
                      key={idx}
                    />
                  ))}
                  {privateRoutes.map((route, idx) => (
                    <Route
                      path={route.path}
                      element={
                        <AuthLayout>
                          {route.component}
                        </AuthLayout>}
                      key={idx}
                    />
                  ))}
                  <Route path='error' element={<Error404 />} />
                  <Route path='*' element={<Navigate to='/error' />} />
                </Routes>
              </HeaderHeading.Provider>
            </NotificationCount.Provider>
          </Context.Provider>
        </StorePopup.Provider>
      </SelectedCheckboxes.Provider>
    </React.Fragment>
  );
}

export default App;