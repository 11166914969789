import React from 'react'
import { numberWithCommas } from '../../../constants/utils';

const Index = ({ orderDetails }: any) => {

    const sumOfNumbers = (arr: any, key: string) => {
        let sum = 0;
        for (let i = 0; i < arr?.length; i++) {
            sum += arr[i][key] ? arr[i][key] : 0;
            // console.log('arr', sum)
        }
        return sum;
    }

    return (
        <>
            <div className="container mt-3" style={{overflow:"scroll"}}>
                <table className="table table-bordered text-center">
                    <thead>
                        <tr>
                            <th rowSpan={2}>S.No.</th>
                            <th rowSpan={2}>Month</th>
                            <th colSpan={2}>Invoice Generated</th>
                        </tr>
                        <tr>
                            <th>Qty</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderDetails?.map((item: any, index: any) => {
                            return (Number(item?.totalAmountOfOrders) != 0) ? (<tr key={index} >
                                <td>{index + 1}</td>
                                <td>{item.monthYear}</td>
                                <td>{item.totalOrders}</td>
                                <td>{numberWithCommas(parseFloat(Number(item.totalAmountOfOrders)?.toFixed(2)))}</td>
                            </tr>) : null
                        }

                        )}
                        <tr>
                            <td className='fw-700' colSpan={2}>Total</td>
                            <td className='fw-700'>{sumOfNumbers(orderDetails, 'totalOrders')}</td>
                            <td className='fw-700'>₹ {numberWithCommas(sumOfNumbers(orderDetails, 'totalAmountOfOrders'))}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Index