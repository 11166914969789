import { useState, useEffect, useContext } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";
import { HeaderHeading } from "../../../../Context";

const { GeneralAccounts } = organisms


const Index = () => {

    const { setHeader }: any = useContext(HeaderHeading);
    const [initialLoad, setInitialLoad] = useState(false)
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [listData, setListData] = useState([])

    useEffect(() => {
        setHeader("Accounts - General Accounts")
        setCurrentPage(1)
        if (currentPage == 1) {
            accountList()
        }
    }, [limitPerPage])

    useEffect(() => {
        if (initialLoad) {
            accountList()
        } else {
            setInitialLoad(true)
        }
    }, [currentPage])


    const accountList = async () => {
        try {
            const response = await service.accounts.ViewGeneralAccountList({
                "page": currentPage,
                "limit": limitPerPage,
            });
            setListData(response.data)

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Wrapper>
                <GeneralAccounts
                    listData={listData}
                    limitPerPage={limitPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setLimitPerPage={setLimitPerPage}
                />
            </Wrapper>
        </>
    )
}

export default Index