import React, { useState, useEffect, useContext } from "react";
import organisms from "../../../components/organisms";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import service from "../../../api/services";
import { HeaderHeading } from "../../../Context";
import { Default_Page_Limit, Default_Page_Number } from "../../../constants/defaultValues";

const { Brand } = organisms
const Index = () => {
    const { setHeader }: any = useContext(HeaderHeading);
    const [search, setSearch] = useState<string | undefined>();
    const [storeBrandList, setStoreBrandList] = useState()


    useEffect(() => {
        setHeader("Brand")
        getStoreBrandList()
    }, [])

    const getStoreBrandList = async () => {
        try {
            const response = await service.brandService?.brandList({search:search})
            if (response.status === 200) {
                setStoreBrandList(response?.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <Wrapper>
            <Brand
                storeBrandList={storeBrandList}
                searchCallBack={(e:string)=>setSearch(e)}
                search={search}
                setStoreBrandList={setStoreBrandList}
            />
        </Wrapper>
    )
}

export default Index