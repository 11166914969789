import React, { useState, useEffect } from 'react'
import { numberWithCommas } from '../../../constants/utils'
import atoms from '../../atoms'
import ConfirmationModal from '../ConfirmationModal'

const { Button } = atoms

const Index = ({ productCartData, findLandingDiscount, checkout }: any) => {
    const [total, setTotal] = useState({
        totalSRP: 0,
        totalLandingCost: 0,
    })
    const [placeOrderConfirmation, setPlaceOrderConfirmation] = useState<boolean>(false)
    useEffect(() => {
        countTotalSRP()
    }, [productCartData?.data])
    
    const handlePlaceOrder = () => {
        checkout(productCartData)
    }  

    const countTotalSRP = () => {
        if (productCartData?.data) {
            const data = productCartData?.data

            const { totalSRP, totalLandingCost } = data.reduce((previousValue: any, currentValue: any) => {
                const productSRP = Number(currentValue?.product?.product_srp) || 0;
                const landingDiscount = Number(currentValue?.landing_cost) || 0;

                previousValue.totalSRP += productSRP;
                previousValue.totalLandingCost += landingDiscount;
                return previousValue;
            }, { totalSRP: 0, totalLandingCost: 0 });

            setTotal({ totalSRP, totalLandingCost });
        }
    }

    const payableAmount = (Data: any, landingCost: number) => {
        let sum = (Number(landingCost)) +
            (Number(Data?.inventory_account_balance) +
            Number(Data?.current_account_balance) +
                Number(Data?.deposit_account_balance) +
                Number(Data?.pending_amount))

        return (parseFloat(sum.toFixed(2)))
    }


    return (
        <div className="checkout-card">
            <h2 className='mb-0 fs-18 fw-600'>Billing Summary</h2>

            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3 mt-md-4">
                <h2 className='mb-0 fs-12 fs-md-14 fw-500'>Current Total Order Value ( Total SRP - ₹{numberWithCommas(total?.totalSRP)} )</h2>
                <h2 className='mb-0 fs-12 fs-md-14 fw-400'>₹{numberWithCommas(parseFloat(Number(total.totalLandingCost).toFixed(2)))}</h2>
                {/* <h2 className='mb-0 fs-12 fs-md-14 fw-500'>₹{numberWithCommas(total?.totalSRP)}</h2> */}
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-12 fs-md-14 fw-500'>Orders Placed - Pending for delivery</h2>
                <h2 className='mb-0 fs-12 fs-md-14 fw-400'>₹{numberWithCommas(parseFloat((Number(productCartData?.store_account_details?.pending_amount) || 0).toFixed(2))) || 0}</h2>
            </div>
            <hr className="mt-3" style={{ border: '1px solid #22222260', }} />
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-12 fs-md-14 fw-500'>Inventory Account Balance</h2>
                <h2 className='mb-0 fs-12 fs-md-14 fw-400'>₹{numberWithCommas(parseFloat((Number(productCartData?.store_account_details?.inventory_account_balance) || 0).toFixed(2))) || 0}</h2>
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-12 fs-md-14 fw-500'>Current Account Balance Payable</h2>
                <h2 className='mb-0 fs-12 fs-md-14 fw-400'>₹{numberWithCommas(parseFloat((Number(productCartData?.store_account_details?.current_account_balance) || 0).toFixed(2))) || 0}</h2>
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-12 fs-md-14 fw-500'>Less : Deposit Account Balance</h2>
                <h2 className='mb-0 fs-12 fs-md-14 fw-400'>₹{numberWithCommas(parseFloat((Number(productCartData?.store_account_details?.deposit_account_balance) || 0).toFixed(2))) || 0}</h2>
            </div>
            <hr className="mt-3" style={{ border: '1px solid #22222260', }} />
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-12 fs-md-14 fw-700'>Net Balance Payable to Specxweb</h2>
                <h2 className='mb-0 fs-12 fs-md-14 fw-500'>₹{numberWithCommas(payableAmount(productCartData?.store_account_details, total.totalLandingCost))}</h2>
            </div>
            <hr className="mt-3" style={{ border: '1px solid #22222260', }} />
            <div className="d-flex gap-1 flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-12 fs-md-14 fw-700'>Additional Credit Provided by SpecxWeb </h2>
                <h2 className='mb-0 pe-0 fs-12 fs-md-14 fw-500'>₹{numberWithCommas(parseFloat((Number(productCartData?.store_account_details?.additional_credit) || 0).toFixed(2))) || 0}</h2>
            </div>
            <hr className="mt-3" style={{ border: '1px solid #22222260', }} />


            <Button
                className='mb-0 fs-18 fw-500 text-white border-1 w-100 mt-4 proceed-btn'
                isDisabled={!productCartData?.data || productCartData?.data.length === 0 || (Number(productCartData?.store_account_details?.additional_credit) < (payableAmount(productCartData?.store_account_details, total.totalLandingCost)))}
                onClick={() => {setPlaceOrderConfirmation(!placeOrderConfirmation)}}>Place Order</Button>
             {(Number(productCartData?.store_account_details?.additional_credit)< (payableAmount(productCartData?.store_account_details, total.totalLandingCost))) &&   <p className="error-text text-center mt-1">{"Kindly clear your balance to place an order"}</p>}


            {/* <Button
                className='mb-0 fs-18 fw-500 text-white border-1 w-100 mt-4 proceed-btn'
                isDisabled={(Number(productCartData?.store_account_details?.additional_credit) < (payableAmount(productCartData?.store_account_details, total.totalLandingCost)))}
                onClick={() => { checkout(productCartData) }}>Place Order</Button>
             {(Number(productCartData?.store_account_details?.additional_credit)< (payableAmount(productCartData?.store_account_details, total.totalLandingCost))) &&   <p className="error-text text-center mt-1">{"Kindly clear your balance to place an order"}</p>} */}

            {
                placeOrderConfirmation && <ConfirmationModal
                    show={placeOrderConfirmation}
                    onHideButtonText={"Cancel"}
                    confirmButtonText={"OK"}
                    handleConfirmItem={() => { handlePlaceOrder(); setPlaceOrderConfirmation(!placeOrderConfirmation) }}
                    onHide={() => setPlaceOrderConfirmation(!placeOrderConfirmation)}
                    message={'proceed'} />
            }  
        </div>
    )
}

export default Index