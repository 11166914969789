import React, { useContext, useEffect, useState } from 'react'
import organisms from '../../../components/organisms'
import molecules from '../../../components/molecules'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import { HeaderHeading } from '../../../Context'
import service from '../../../api/services'
const { TopBar } = molecules
const { InventoryDashboard } = organisms

const Index = () => {
    const { setHeader }: any = useContext(HeaderHeading);
    const [inventoryDetails, setInventoryDetails] = useState()

    const getInventoryDetails = async () => {
        try {
            const response = await service.DashboardService.inventoryDetails()
            if (response.status === 200) {
                setInventoryDetails(response.data.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        setHeader("Dashboard - Inventory ")
        getInventoryDetails()
    }, [])

    return (
        <Wrapper>
            <TopBar />
            <InventoryDashboard
                inventoryDetails={inventoryDetails}
            />
        </Wrapper>
    )
}

export default Index