import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { dashboard } = Dictionary

const DashboardService = {
    dashboard: async () => {
        return axios.get(BASE_URL + dashboard.dashboard(),)
    },
    inventoryDetails: async () => {
        return axios.get(BASE_URL + dashboard.inventoryDetails(),)
    },
    orderDetails: async () => {
        return axios.get(BASE_URL + dashboard.orderDetails(),)
    },
    accountList: async () => {
        return axios.get(BASE_URL + dashboard.accountSearch(),)
    },
    glAccountReport: async (payload: {}) => {
        return axios.post(BASE_URL + dashboard.glAccountReport(), payload)
    },

}
export default DashboardService

