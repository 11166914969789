import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import { HeaderHeading } from '../../../../Context'
import molecules from '../../../../components/molecules'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { type } from '@testing-library/user-event/dist/type';
const { TopBar } = molecules
const { OrderCart } = organisms
const Index = () => {
  const navigate = useNavigate()
  const [productList, setProductList] = useState([])
  const [productCartData, setProductCartData] = useState([])
  const { setHeader }: any = useContext(HeaderHeading);



  const handleSearch = async (search: string) => {
    try {
      const response = await service.orderCardDetail.search({
        search: search,
      })
      if (response.status === 200) {
        setProductList(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const viewPurchasedCart = async () => {
    try {
      const response = await service.orderCardDetail.view()
      if (response.status === 200) {
        setProductCartData(response?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSearchSelect = async (selectedItem: String) => {
    const payload = {
      "product_id": selectedItem
    }
    try {
      const response = await service.orderCardDetail.addProduct(payload)
      if (response?.status == 200) {
        viewPurchasedCart()
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const removeItem = async (id: string) => {
    try {
      const response = await service.orderCardDetail.removeProduct(id)
      if (response.status === 200) {
        viewPurchasedCart()
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const findLandingDiscount = (product: any, store: any) => {
    if (!product?.brand?.brand_name || !product.product_type_id || !product.product_srp || !store?.brands) {
      return null;
    }

    const brand = store.brands.find((brand: any) => {
      if (brand?.brand_name == product?.brand?.brand_name) {
        return brand
      }
    })
    if (!brand || !brand?.store_brands?.store_brand_product_type_data) {
      return null;
    }

    const productTypeData = brand?.store_brands?.store_brand_product_type_data.find((data: any) => {
      if (data.product_type_id === product?.product_type_id) {
        return data
      }
    });
    if (!productTypeData || !productTypeData?.brand_landing_cost) {
      return null;
    }
    let percentage = parseFloat((Number(productTypeData.brand_landing_cost) / 100).toFixed(2))
    const brandLandingCost = (product.product_srp * percentage)
    return brandLandingCost;
  }

  const getPayload = (productData: any) => {
    const purchase_cart_items: any = []
    productData?.length>0 && productData.map((items: any) => {
      purchase_cart_items.push({
        purchase_cart_item_id: items?.id,
        landing_cost: findLandingDiscount(items?.product, items?.store)
      })
    })
    checkoutPurchaseCart(purchase_cart_items)
  }


  const checkoutPurchaseCart = async (productData: any) => {
    const payload = {
      purchase_cart_items: productData
    }
    try {
      const response = await service.orderCardDetail.checkout(payload)
      if (response.status === 200) {
        viewPurchasedCart()
        navigate('/procurement/order/history')
      }
    } catch (error) {
      console.log('error', error)
    }

  }

  useEffect(() => {
    setHeader("Procurement - Order Cart")
    viewPurchasedCart()
  }, [])

  return (
    <Wrapper>
      <TopBar heading={'Order Cart'} />
      <div className="row mt-md-4 mt-2">
        <OrderCart
          handleSearch={handleSearch}
          handleSearchSelect={handleSearchSelect}
          productList={productList}
          productCartData={productCartData}
          removeItem={removeItem}
          checkout={(productData:any)=>getPayload(productData)}
          findLandingDiscount={(product: any, store: any)=>findLandingDiscount(product, store)} />
      </div>
    </Wrapper>

  )
}

export default Index