import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import atoms from '../../../components/atoms'
import { useToasts } from 'react-toast-notifications'
import { HeaderHeading } from '../../../Context'
import service from '../../../api/services'
const { Button, Input } = atoms

const Index = () => {
    const { setHeader }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const [password, setPassword] = useState({
        old_password: '',
        new_password: '',
        confirm_password: '',
    })
    const handleSubmit = async () => {
        setIsSubmit(true)
        try {
            if (password.new_password === password.confirm_password) {
                const payload = {
                    old_password: password.old_password,
                    new_password: password.new_password
                }
                const response = await service.auth.changePassword(payload)
                if (response.status === 200) {
                    addToast(response?.data?.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    setPassword({
                        old_password: '',
                        new_password: '',
                        confirm_password: '',
                    })
                    setIsSubmit(false)
                }
            } else {
                addToast('New Password and Confirm Password must be same ', {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    useEffect(() => {
        setHeader("Change Password")
    }, [])
    return (
        <Wrapper>
            <div className="w-md-50">
                <div className="">
                    <Input label={"Old Password"} type={'text'}
                        placeholder={"Enter Old Password"}
                        value={password.old_password}
                        onChange={(e: any) => setPassword({ ...password, old_password: e.target.value })}
                        errors={(!password.old_password && isSubmit) && 'This field is required'}
                    />
                </div>
                <div className="mt-3">
                    <Input label={"New Password"} type={'text'}
                        placeholder={"Enter Password"}
                        value={password.new_password}
                        onChange={(e: any) => setPassword({ ...password, new_password: e.target.value })}
                        errors={(!password.new_password && isSubmit) && 'This field is required'}
                    />
                </div>
                <div className="mt-3">
                    <Input label={"Confirm Password"} type={'text'}
                        placeholder={"Enter Password"}
                        value={password.confirm_password}
                        onChange={(e: any) => setPassword({ ...password, confirm_password: e.target.value })}
                        errors={(!password.confirm_password && isSubmit) && 'This field is required'}
                    />
                </div>
                <div className="mt-5 text-md-end text-center">
                    <Button
                        onClick={() => handleSubmit()}>Submit</Button>
                </div>
            </div>
        </Wrapper>
    )
}

export default Index