import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { PurchasedCard } = Dictionary

const orderCardDetail = {
  search: async (paramsObj:{}) => {
    return axios.get(BASE_URL + PurchasedCard.searchApi(), { params: paramsObj })
  },
  view: async () => {
    return axios.get(BASE_URL + PurchasedCard.viewApi())
  },
  addProduct:async(payload:{})=>{
    return axios.post(BASE_URL + PurchasedCard.addProductApi(), payload)
  },
  removeProduct:async(id:string)=>{
    return axios.delete(BASE_URL + PurchasedCard.removeProductApi(id))
  },
  checkout:async(payload:{})=>{
    return axios.post(BASE_URL + PurchasedCard.checkoutApi(), payload)
  },
}
export default orderCardDetail
