import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import atoms from '../../atoms'
import constant from '../../../constants/constant'
import './index.scss'
import { RxCross2 } from "react-icons/rx";
const { Button, Image } = atoms
const SidePopUp = (props: any) => {
    const handleUpdateStatus = (status: number) => {
        props.updateVerifiactionStatus(props?.productDetail?.id, status)
        props.onHide()
    }
    return (
        <Modal className="rounded-0"
            isOpen={props.show}
            wrapClassName="modal-right"
        >
            <ModalHeader className="d-flex border-0 position-relative" >
                {/* <div className="mt-4 fs-24 fw-600">{props.header}</div> */}
                <div className="mt-4 fs-24 fw-600 mb-0">Product Name</div>
                <div className="position-absolute py-1 px-2" style={{ top: '15%', right: '3%', backgroundColor: 'transparent', border: '1px solid #222', borderRadius: '50%', cursor: 'pointer' }} onClick={props.onHide}><RxCross2 style={{ color: '#222' }} /></div>
            </ModalHeader>
            <ModalBody className="pt-md-0">
                <div className=' d-flex gap-3 align-items-center mb-4'>
                    <h2 className='mb-0 fs-18 fw-700 secondary-color '>Product ID:</h2>
                    <div className='d-flex justify-content-around align-items-center py-2 add-product-custom-div cursor-pointer' >
                        <h2 className='mb-0 fs-16 fw-600 secondary-color'>{props?.productDetail?.product_id}</h2>
                        <div className=''>
                            <Image src={"CopyIcon"} onClick={() => navigator.clipboard.writeText(props?.productDetail?.product_id)} />
                        </div>
                    </div>

                </div>
                <hr className="mt-3" style={{ border: '1px dashed #222', }} />
                <div className="mt-4 fs-20 fw-600 secondary-color">Basic Details</div>
                <div className="d-flex justify-content-between mt-20 info-style" >
                    <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Product Type :</h2>
                    <h2 className="mb-0 fs-16 fw-400 secondary-color info-text">{props?.productDetail?.product_type?.name}</h2>
                </div>
                <div className="d-flex justify-content-between info-style" >
                    <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Brand :</h2>
                    <h2 className="mb-0 fs-16 fw-400 secondary-color info-text">{props?.productDetail?.brand?.brand_name}</h2>
                </div>
                <div className="d-flex justify-content-between info-style" >
                    <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Model Number :</h2>
                    <h2 className="mb-0 fs-16 fw-400 secondary-color info-text">{props?.productDetail?.modal_number}</h2>
                </div>
                <div className="d-flex justify-content-between info-style" >
                    <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Brand Colour Code :</h2>
                    <h2 className="mb-0 fs-16 fw-400 secondary-color info-text">{props?.productDetail?.brand_color_code}</h2>
                </div>
                <div className="d-flex justify-content-between info-style" >
                    <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">SW Color Name:</h2>
                    {props.productDetail?.specx_colors && props.productDetail?.specx_colors?.map((color: any) => {
                        <h2 className="mb-0 fs-16 fw-400 secondary-color info-text">{color?.color_name}</h2>
                    })}
                </div>
                <div className="d-flex justify-content-between info-style" >
                    <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">SW Lens Color :</h2>
                    <h2 className="mb-0 fs-16 fw-400 secondary-color info-text">{props?.productDetail?.sw_lens_color}</h2>
                </div>
                <div className="d-flex justify-content-between info-style" >
                    <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Glass Size :</h2>
                    <h2 className="mb-0 fs-16 fw-400 secondary-color info-text">{props?.productDetail?.glass_size}</h2>
                </div>
                <div className="d-flex justify-content-between info-style" >
                    <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Temple Size :</h2>
                    <h2 className="mb-0 fs-16 fw-400 secondary-color info-text">{props?.productDetail?.temple_size}</h2>
                </div>
                <div className="d-flex justify-content-between info-style" >
                    <h2 className="mb-0 fs-16 fw-600 secondary-color info-text">Bridge size :</h2>
                    <h2 className="mb-0 fs-16 fw-400 secondary-color info-text">{props?.productDetail?.bridge_size}</h2>
                </div>

                <div className="mt-4 fs-20 fw-600 secondary-color">Product Images</div>
                <div className='row'>
                    {props?.productDetail?.product_images && props?.productDetail?.product_images.map((productImage: any) => {
                        return (
                            <div className="d-flex gap-1 flex-wrap gap-4 col-2">
                                <div className="mt-3 info-cover-img position-relative" >
                                    <Image className="info-cover-img" src={productImage?.url} />
                                    {/* <h2 className='fs-12 fw-500 position-absolute w-100 text-center cover-text-bg mb-0' style={{ bottom: '0', }}>Cover Image</h2> */}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <hr className="mt-5" style={{ border: '1px dashed #222', }} />

            </ModalBody>


            <ModalFooter className="border-0 d-flex" style={{ flexWrap: 'inherit' }}>
                <Button className="fs-16 fw-500 reject-btn" onClick={() =>
                    handleUpdateStatus(constant.VERIFICATION_STATUS.REWORK)
                }>
                    Reject
                </Button>{" "}
                <Button className="fs-16 fw-500 approve-btn" onClick={() =>
                    handleUpdateStatus(constant.VERIFICATION_STATUS.VERIFIED)
                }>
                    Approve
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default SidePopUp