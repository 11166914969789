import React, { useState } from 'react'
import atoms from '../../../atoms'
import constant from '../../../../constants/constant';
import molecules from '../../../molecules';
import moment from 'moment'
import { MyComponentProps, procurementDetail } from "./interface"
import { RxCrossCircled } from "react-icons/rx";
import { numberWithCommas } from '../../../../constants/utils'

const { ConfirmationModal,TopBar } = molecules
const { Button, Table } = atoms

const Index: React.FC<MyComponentProps> = ({ orderHistoryItems, cancelButton }) => {
    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const [procurementItemId, setProcurementItemId] = useState<string>("")

    const columns = [
        { name: 'Order ID/Warranty Claim ID', selector: (row: procurementDetail) => row?.order?.order_id_display ? row?.order?.order_id_display : (row?.warranty_claimed_product?.claim_id || "-"),minWidth:"120px" },
        { name: 'Product ID', selector: (row: procurementDetail) => row?.product?.product_id_display,minWidth:"120px" },
        { name: 'Product Type', selector: (row: procurementDetail) => row?.product?.product_type?.name,minWidth:"120px" },
        { name: 'Cover Photo', selector: (row: procurementDetail) => <img src={row?.product?.images[0] && row?.product?.images[0]} className='w-50 rounded object-contain' /> },
        { name: 'Brand', selector: (row: procurementDetail) => row?.product?.brand?.brand_name || '-',minWidth:"120px" },
        { name: 'Model No', selector: (row: procurementDetail) => row?.product?.modal_number || '-',minWidth:"120px" },
        { name: 'Brand Color Code', selector: (row: procurementDetail) => row?.product?.brand_color_code || '-',minWidth:"120px" },
        { name: 'Glass Size', selector: (row: procurementDetail) => row?.product?.glass_size || '-',minWidth:"120px" },
        { name: 'SRP', selector: (row: procurementDetail) => `₹${numberWithCommas(parseFloat(Number(row?.product?.product_srp)?.toFixed(2)))}` || '-',minWidth:"120px" },
        { name: 'Landing Cost', selector: (row: procurementDetail) => `₹${numberWithCommas(parseFloat(Number(row?.landing_cost)?.toFixed(2)))}` || '-',minWidth:"120px" },
        { name: 'Status', selector: (row: any) => row?.status || '-',minWidth:"120px" },
        { name: 'Action', selector: (row: any) => row?.action || '-', minWidth:"150px"},
    ]

    const _handleDeletePopup =(id:string)=>{
        setProcurementItemId(id)
        setDeletePopup(!deletePopup)
        }
    return (
        <div>
            <div className='row'>
                <div className='row align-items-center mb-3'>
                    <TopBar />
                </div>
                <div className="col-sm-4 d-flex fs-20 fw-800 secondary-color " style={{minWidth:"20rem"}}>Procurement ID : <span className="mx-1 fs-20 fw-400 secondary-color">{orderHistoryItems?.procurement_display_id}</span></div>
                <div className="col-sm-4 mt-sm-0 mt-3 d-flex fs-20 fw-800 secondary-color">Store Name :<p className="mx-1 fs-20 fw-400 secondary-color">{orderHistoryItems?.store?.store_name}</p></div>
                <div className="col-sm-4 d-flex fs-20 fw-800 secondary-color"> Date :<p className="mx-1 fs-20 fw-400 secondary-color">{orderHistoryItems?.createdAt && moment(orderHistoryItems?.createdAt).format("DD/MM/YYYY")}</p></div>
            </div>
            <hr className="my-3" style={{ border: '1px dashed #222' }} />
            <div>
                <Table
                    AdditionalClassName="add-inventory-table table-height-50"
                    columns={columns}
                    data={orderHistoryItems?.procurement_items?.map((item: any) => {
                        return ({
                            ...item,
                            status:
                                item.status === constant.PROCUREMENT_ITEM_STATUS.PENDING ? (
                                    <div className="background-light-yellow border-product-radius">
                                        <p className="color-yellow m-1 p-1">{'Pending'}</p>
                                    </div>)
                                    : item.status === constant.PROCUREMENT_ITEM_STATUS.TO_BE_DELIVERED ?
                                        (<div className="background-light-yellow border-product-radius">
                                            <p className="color-yellow m-1 p-1">{"To_Be_Delivered"}</p>
                                        </div>)
                                        : item.status === constant.PROCUREMENT_ITEM_STATUS.DELIVERED ?
                                            (<div className="background-light-green border-product-radius">
                                                <p className="color-green m-1 p-1">{"Delivered"}</p>
                                            </div>)
                                            : item.status === constant.PROCUREMENT_ITEM_STATUS.CANCEL ?
                                                (<div className="background-light-red border-product-radius">
                                                    <p className="color-red m-1 p-1">{"Cancel"}</p>
                                                </div>) : ''
                            ,
                            action:
                                <>

                                    {!(item.status === constant.PROCUREMENT_ITEM_STATUS.CANCEL) && <div style={{ overflow: 'unset', textOverflow: 'unset' }}>
                                        {(!item?.order?.order_id_display) &&
                                            <Button className="table-edit-buttons w-100 fs-14 fw-400">
                                                <div className='d-flex align-items-center'
                                                    onClick={() => _handleDeletePopup(item?.id)}>
                                                    <RxCrossCircled className='fs-14 fw-400' />
                                                    <span className='ms-2 mb-0'>Cancel</span>
                                                </div>
                                            </Button>}
                                    </div>}
                                </>
                        })
                    })}
                />
            </div>

            {deletePopup && <ConfirmationModal
                show={deletePopup}
                handleConfirmItem={() => { cancelButton(procurementItemId); setDeletePopup(!deletePopup) }}
                onHide={() => setDeletePopup(!deletePopup)}
                message={'cancel this procurement'} />}
        </div>
    )
}

export default Index