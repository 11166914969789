import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import service from '../../../api/services';
import Wrapper from '../../../components/common/Wrapper/Wrapper';
import organisms from "../../../components/organisms";
import { getDropdown, getDropdownValue } from '../../../constants/utils';

const { StaffAdd } = organisms
const AddStaff = () => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [storeList, setStoreList] = useState()
    const [structuredPermission, setStructuredPermission] = useState<any>([])
    const [defaultPermission, setDefaultPermission] = useState<any>([])

    let params = useParams();
    let staffEditID = params.id || ''
    const [buttonClick, setButtonClick] = useState(false)

    const [staff, setStaff] = useState<any>({
        login_id: "",
        username: "",
        mobile: "",
        email: "",
        password: "",
        role: "",
        store_id: [],
        permissions: []
    })
    const addStaff = async () => {
        let storeID = staff.store_id.map((item: { value: string }) => item.value)
        let payload = {
            mobile: staff?.mobile,
            email: staff?.email,
            password: staff?.password,
            username: staff?.username,
            user_id: staff?.login_id,
            store_ids: storeID,
            permissions: staff?.permissions,
            // role: staff?.value,
        }

        try {
            let response: any
            if (staffEditID) {
                response = await service.staff.edit(staffEditID, payload)
                if (response.status === 200) {
                    addToast(response?.data?.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    navigate(-1)
                }
            } else {
                response = await service.staff.add(payload)
                if (response.status === 201) {
                    addToast(response?.data?.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    navigate(-1)
                }
            }
        } catch (error: any) {
            console.log(error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        setButtonClick(false)
    }
    const getDataById = async () => {
        try {
            let response = await service.staff.getDetailById(staffEditID)
            handleEditData(response?.data?.data.staffDetails.user)
            setDefaultPermission(response?.data?.data?.permissions)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleEditData = (response: any) => {
        let storeID: any = []
        response && response?.store_staffs?.map((value: any) => {
            storeID.push({
                "key": value.store.id,
                "value": value.store.id,
                "label": value.store.store_name,
            })
        })
        let tempEdit = {
            // legal_status: getDropdownValue(Store_Legal_Status, response?.legal_status),
            mobile: response?.mobile,
            email: response?.email,
            // password: response?.password,
            username: response?.username,
            login_id: response?.login_id,
            store_id: storeID,
        }
        setStaff(tempEdit)
    }

    const handleChangePassword = async (password: any) => {
        let payload = {
            password: password
        }
        try {
            let response = await service.staff.changePassword(staffEditID, payload)
            if (response.status === 200) {
                addToast('Password Change successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const getStoreList = async () => {
        const StoreResponse = await service.store.storeList()
        setStoreList(getDropdown(StoreResponse?.data?.data, "store_name"))
    }


    // for remove the tab permission of staff portal
    const removePermissionsByCodes = (obj: any[], permCodes: any[]): any[] => {
        return obj.filter(item => !permCodes.includes(item?.perm_code));
      };

    // for add permission
    const getStructuredPermission = async () => {
        try {
            const response = await service.staff.structuredPermission()
            if (response.status === 200) {
                // remove permission of this tab
                const permCodesToRemove =["BC7Z3"]
                setStructuredPermission(removePermissionsByCodes(response?.data?.data, permCodesToRemove))
                // setStructuredPermission(response?.data?.data)

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        if (buttonClick) {
            addStaff()
        }

    }, [buttonClick])

    useEffect(() => {
        getStoreList()
        if (staffEditID) {
            getDataById()
        }
        getStructuredPermission()
    }, [])
    return (
        <Wrapper>
            <StaffAdd
                staff={staff}
                setStaff={setStaff}
                setButtonClick={setButtonClick}
                handleChangePassword={(password: any) => handleChangePassword(password)}
                storeList={storeList}
                structuredPermission={structuredPermission}
                defaultPermission={defaultPermission}
            />
        </Wrapper>
    )
}
export default AddStaff