import React from "react";
import atoms from "../../../atoms";
import { numberWithCommas, findConstantLabel } from "../../../../constants/utils";
import moment from 'moment'
import constant from "../../../../constants/constant";
import { EntriesProps, EntriesData } from "./interface";

const { Table, Pagination, Search, Input } = atoms

const Index = ({ data, handleChange }: EntriesProps) => {
  const columns = [
    { name: 'Date Time', selector: (row: EntriesData) => `${row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY , H:mm:ss') : "--"}`, sortable: true, maxWidth: "150px" },
    { name: 'Entry number', selector: (row: EntriesData) => row?.new_entry_number ? row?.new_entry_number : "-", maxWidth: "120px" },
    { name: 'Category', selector: (row: EntriesData) => row?.event_type ? findConstantLabel(constant?.ACCOUNTING_EVENTS, row?.event_type) : "-", minWidth: "100px", maxWidth: "150px" },
    { name: 'GL Name', selector: (row: EntriesData) => `${row.account?.gl_account_name ? row.account?.gl_account_name : "--"}`, minWidth: "180px" },
    { name: 'GL Account Number', selector: (row: any) => `${row.account?.gl_number ? row.account?.gl_number : "--"}`, maxWidth: "150px" },
    { name: 'Amount', selector: (row: EntriesData) => row?.transaction_amount, maxWidth: "50px" },
    { name: 'Narration', selector: (row: EntriesData) => row?.narration, minWidth: "270px" },
  ]

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-3 mb-md-0 mb-3">
          <Search className="w-md-50 w-100" searchCallBack={(e: string) => { handleChange("search", e) }} />
        </div>
        <div className="col-md-9 text-md-end">
          <div className="d-md-flex gap-4 align-items-center justify-content-md-end">
            <div className="d-md-flex align-items-center gap-2 mb-md-0 mb-3">
              <label className='inputs-label fs-16 fw-600 secondary-color'>From</label>
              <Input
                type={'Date'}
                className="input-height"
                placeholder={"Enter End Date"}
                onChange={(e: any) => {
                  handleChange("created_from", e.target.value)
                }} />
            </div>
            <div className="d-md-flex align-items-center gap-md-2 gap-4">
              <label className='inputs-label fs-16 fw-600 secondary-color'>To</label>
              <Input
                type={'Date'}
                className="input-height"
                placeholder={"Enter Start Date"}
                onChange={(e: any) => {
                  handleChange("created_to", e.target.value)
                }} />
            </div>
          </div>
        </div>
      </div >
      {(data?.listData?.data && data?.listData?.data?.length > 0) ? <div>
        <Table
          className="adjustable-row-height"
          AdditionalClassName="table-height-50"
          columns={columns}
          data={data?.listData?.data?.map((item: EntriesData, index: number) => {
            return ({
              ...item,
              transaction_amount: <>
                {(item?.transaction_type == constant?.TRANSACTION_TYPE[0]?.value) ?
                  <span>- {numberWithCommas(parseFloat(Number(item?.amount || 0).toFixed(2)))}</span> :
                  (item?.transaction_type == constant?.TRANSACTION_TYPE[1]?.value) ?
                    <span>+ {numberWithCommas(parseFloat(Number(item?.amount || 0).toFixed(2)))}</span> : null}
              </>,
            })
          })}
        />
      </div> : <div className='justify-content-center d-flex mt-5 '>There are no records to display</div>
      }
      {/* <div> */}
      <Pagination
        totalCount={data?.listData?.count}
        currentPage={data?.currentPage}
        totalPages={data?.listData?.totalPages}
        onChangePage={(page: any) => handleChange("currentPage", page)}
        setLimit={(e: number) => handleChange("limitPerPage", e)}
        limit={data?.limitPerPage}
      />
      {/* </div> */}
    </>
  )
}

export default Index