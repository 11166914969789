import React, { useContext, useEffect, useState } from 'react'
import organisms from '../../../components/organisms'
import molecules from '../../../components/molecules'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import { HeaderHeading } from '../../../Context'
import service from '../../../api/services'
const { TopBar } = molecules
const { OrderDashboard } = organisms

const Index = () => {
    const { setHeader }: any = useContext(HeaderHeading);
    const [orderDetails, setOrderDetails] = useState()

    const getOrderDetails = async () => {
        try {
            const response = await service.DashboardService.orderDetails()
            if (response.status === 200) {
                setOrderDetails(response.data.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        setHeader("Dashboard - Sale Order ")
        getOrderDetails()
    }, [])

    return (
        <Wrapper>
            <TopBar />
            <OrderDashboard
                orderDetails={orderDetails}
            />
        </Wrapper>
    )
}

export default Index