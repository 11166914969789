import React from "react";
import atoms from "../../atoms";
const { Image } = atoms
import './Search.scss'
const Index = ({ setSearchVal, data, onHide, searchCallBack, setPreviewTab }: any) => {
    const handleSelect = (selectedItem: any) => {
        searchCallBack(selectedItem)
        // setSearchVal("")
        // setPreviewTab(false)
    }

    const closeSearchTab = () => {
        setSearchVal("")
    }
    return (

        <div className="custom-search card p-0">
            {data?.length > 0 ? data?.map((item: any) => {
                return (
                    <li className="list-unstyled list-hover cursor-pointer p-1 px-3 d-flex">
                        <div className='fs-16 cursor-pointer my-auto'>
                            <div className="mx-sm-3 mx-1 my-auto" style={{ width: "50px" }}>
                                <Image src={item?.images[0]} className='w-100 rounded object-contain searchClass' />
                            </div>
                        </div>
                        <div className='fs-16 cursor-pointer col-9' onClick={() => handleSelect(item)}>

                            <h2 className="fs-14 fw-600 text-capitalize">{item?.brand?.brand_name}, {item?.product_type?.name}</h2>
                            <div className='d-flex'>
                                <h3 className="fs-12 fw-600 secondary-color">Model No : </h3>
                                <h3 className="fs-12 fw-600 secondary-color margin-left-10 ">{item?.modal_number}</h3>
                            </div>
                            <div className='d-md-flex' >
                                <h3 className="fs-12 fw-600 secondary-color" >Product ID : </h3>
                                <h3 className=" fs-12 fw-600 secondary-color margin-left-10 ">{item?.product_id_display}</h3>
                            </div>
                        </div>
                    </li>
                )
            }) :
                <li className="list-unstyled list-hover cursor-pointer p-0 d-flex">
                    <div className='fs-16 cursor-pointer col-11'>
                        <div className="fw-500 text-capitalize py-3 px-3" onClick={closeSearchTab}>
                            No product found
                        </div>
                    </div>
                </li>
            }
        </div>
    )
}
export default Index