export const validationRules: any = {
  username: {
    required: true,
  },
  mobile: {
    required: true,
    minLength: 10,
    maxLength: 10,
  },
  email: {
    required: true,
    pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  },
  login_id:{
    required: true,
    // pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#])[a-zA-Z\d@#]+$/,
  }
  // role: {
  //   label: { required: true },
  // },
}
