import React, { useState, useRef } from 'react'
import atoms from '../../atoms'
import molecules from '../../molecules'
import { InventoryAuditProps } from './interface'
import constant from '../../../constants/constant'

const { Input, Select, CustomSearch, Button } = atoms
// const { AuditProduct } = molecules

const Index = ({ handleBarcodeSearch, updateItemCondition, barcodeData, handleChange, updateData }: InventoryAuditProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const searchTabContainer = useRef<HTMLDivElement>(null);

    const searchCallBack = (search: string) => {
        handleBarcodeSearch(search)
    }

    return (
        <>
            <div className='col-12 col-md-8 mt-25'>
                <div className='mt-4' ref={searchTabContainer}>
                    <div className=" position-relative" onClick={() => setIsOpen(!isOpen)} >
                        <CustomSearch
                            setIsOpen={setIsOpen}
                            searchCallBack={searchCallBack}
                            placeholder='Enter Barcode Number'
                            className='fs-16'
                            heading="Search"
                            buttonStyle="w-25" />
                    </div>
                </div>
            </div>
            {barcodeData ? <>
                <div className='my-3'>
                    {/* <AuditProduct barcodeData={barcodeData} /> */}
                </div>
                <div style={{ marginTop: "2rem" }}>
                    <div className='my-3'>
                        <div className='col-4'>
                            <label className={`inputs-label mb-2 fs-16 fw-600`}>Condition of the items</label>
                            <Select
                                options={constant.INVENTORY_ITEM_CONDITION}
                                name={"item_condition"}
                                value={updateData?.item_condition}
                                className={"w-100"}
                                placeholder={"Select Condition"}
                                onChange={(e: any) => { handleChange("item_condition", e) }}
                            />
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className='col-12'>
                            <label className={`inputs-label mb-2 fs-16 fw-600`}>Product Condition Remark</label>
                            <Input
                                type={'text'}
                                name={"conditionRemark"}
                                value={updateData.conditionRemark}
                                placeholder={"Enter Description Here"}
                                onChange={(e: any) => { handleChange("conditionRemark", e.target.value) }} />
                        </div>
                    </div>
                </div>
                <div className='mx-1 mt-20'>
                    <Button name={"Save"} className={"w-100"} onClick={() => { updateItemCondition(barcodeData?.id) }} />
                </div>
            </> :
                <div className='mt-3 d-flex justify-content-center dark-gray'>
                    <span>There are no records to display</span>
                </div>}
        </>
    )
}

export default Index