import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './assets/scss/theme.scss';
import { ToastProvider } from 'react-toast-notifications';
const element = document.getElementById('root');
let root: any
if (element !== null) {
  root = ReactDOM.createRoot(element)
}
root.render(
  <React.Fragment>
    <BrowserRouter>
      <ToastProvider>
        < App />
      </ToastProvider>
    </BrowserRouter>
  </React.Fragment>
);
