import React, {useContext, useEffect,useState} from "react";
import organisms from "../../../components/organisms";
import { HeaderHeading } from "../../../Context";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import service from "../../../api/services";

const { Setting } = organisms;

const Index = () => {
  const [printPrescription, setPrintPrescription]= useState(false)
  const { setHeader }: any = useContext(HeaderHeading);


  const getSetting = async () => {
    try {
      let response = await service?.SettingService?.getList()
      setPrintPrescription(response?.data?.data?.print_prescription)
    } catch (error) {
      console.log(error)
    }
  }

  const updateSetting = async () => {

    try {
      let response = await service?.SettingService?.updateSetting()
      setPrintPrescription(response?.data?.data?.print_prescription)
      if(response.status === 200){
    getSetting()
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    setHeader("Setting")
    getSetting()
  }, [])

  return (
    <Wrapper>
      <Setting printPrescription={printPrescription} updateSetting={updateSetting}/>
    </Wrapper>
  );
};

export default Index;
