import StaffAdd from './Staff/AddStaff'
import Staff from './Staff/index'
import OrderCart from './Procurement/OrderCart'
import OrderHistory from './Procurement/OrderHistory'
import OrderHistoryItems from './Procurement/ItemsDetail'
import OrderReceived from './Procurement/OrderReceived'
import OrderCancelled from './Procurement/OrderCancelled'

import TransferRequested from './TransferRequest/Requested'
import TransferRequestMoveOut from './TransferRequest/MovedOut'
import TransferRequestCancelled from './TransferRequest/Cancelled'

import ProductList from './Procurement/Product'
import ProductDetails from './ProductDetails'
import Inventory from './Inventory'
import AddInventoryOrganism from './Inventory/Addition/AddInventory'
import Transaction from './Accounts/Transaction'
import GeneralAccounts from './Accounts/GeneralAccounts'
import InventoryAudit from './InventoryAudit'
import Brand from './Brand'
import InventoryReport from './Report/InventoryReport'
import DailyActivityReport from './Report/DailyActivityReport'
import GSTOutReport from './Report/GSTOutputReport'
import SalesReport from './Report/SalesReport'
import Dashboard from './Dashboard'
import InventoryDashboard from './Dashboard/inventory'
import OrderDashboard from './Dashboard/order'
import AccountsDashboard from './Dashboard/accounts'
import AddProduct from "./AddProduct"
import InventoryAdditionInvoiceReport from "./Report/InventoryAdditionReport/InvoiceWise"
import InventoryAdditionBarcodeReport from "./Report/InventoryAdditionReport/BarcodeWise"
import Setting from "./Setting"

import ApprovedInventory from "./Inventory/ApprovedInventory"


const organisms = {
    StaffAdd,
    Staff,
    OrderCart,
    OrderHistory,
    OrderHistoryItems,
    OrderReceived,
    OrderCancelled,
    TransferRequested,
    TransferRequestMoveOut,
    TransferRequestCancelled,
    ProductList,
    ProductDetails,
    Inventory,
    Transaction,
    GeneralAccounts,
    InventoryAudit,
    Brand,
    InventoryReport,
    DailyActivityReport,
    GSTOutReport,
    SalesReport,
    InventoryAdditionBarcodeReport,
    InventoryAdditionInvoiceReport,
    Dashboard,
    InventoryDashboard,
    OrderDashboard,
    AccountsDashboard,
    AddInventoryOrganism,
    AddProduct,
    Setting,
    ApprovedInventory,
}
export default organisms
