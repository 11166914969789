import React, { useEffect, useState } from "react";
import { BsArrowDownShort, BsArrowUpShort, BsXCircle } from 'react-icons/bs';
import atoms from "../../atoms";
import './index.scss'

const { CustomImages }= atoms

const Index = ({ storeBrandList }: any) => {
    const [tableData, setTableData] = useState<any>()
    const [sortAsc, setSortAsc] = useState(true);
    const [sortTypeAsc, setSortTypeAsc] = useState(true);

    const sortProductTypesByName = () => {
        const sortedData = [...tableData].sort((a, b) => {
            if (sortTypeAsc) {
                return a.brand_name.localeCompare(b.brand_name);
            } else {
                return b.brand_name.localeCompare(a.brand_name);
            }
        }).map((obj) => ({
            ...obj,
            product_types: [...obj.store_brands?.store_brand_product_type_data].sort((a, b) => {
                if (sortTypeAsc) {
                    return a.product_type_name.localeCompare(b.product_type_name);
                } else {
                    return b.product_type_name.localeCompare(a.product_type_name);
                }
            }),
        }));

        setTableData(sortedData);
        setSortTypeAsc(!sortTypeAsc);
    };

    const sortDataByName = () => {
        const sortedData = [...tableData].sort((a, b) => {
            if (sortAsc) {
                return a.brand_name.localeCompare(b.brand_name);
            } else {
                return b.brand_name.localeCompare(a.brand_name);
            }
        });

        setTableData(sortedData);
        setSortAsc(!sortAsc)
    };

    useEffect(() => {
        setTableData(storeBrandList)
    }, [storeBrandList])

    return (
        <>
            {tableData?.length > 0 ? <table className='mb-5 w-100 position-relative custom_table'>
                <thead className='table-thead background-blue color-black position-sticky sticky-top '>
                    <th className='border-right p-1'>
                        <div className='d-flex' style={{ width: "190px" }}>
                            <div className=' brandName p-2  d-flex align-items-center'>
                                <span className="fw-500"> Brand Name{sortAsc ? <BsArrowDownShort onClick={sortDataByName} className="cursor-pointer" /> :
                                    <BsArrowUpShort className="cursor-pointer" onClick={sortDataByName} />}</span>
                            </div>
                        </div>
                    </th>
                    <th className='text-center'>   <span className="fw-500">Product Type
                        {/* {sortTypeAsc ? <BsArrowDownShort onClick={sortProductTypesByName} className="cursor-pointer" /> :
                        <BsArrowUpShort className="cursor-pointer" onClick={sortProductTypesByName} />} */}
                    </span>
                    </th>
                    {/* <th className='text-center'>Brand Margin &#40;%&#41; </th> */}
                    <th className='text-center'>Brand Landing Cost &#40;%&#41;</th>
                    <th className='text-center'>Brand Discount &#40;%&#41;</th>
                </thead>

                <tbody className='mt-3 table-tbody' >
                    {tableData?.map((brand: any, brandIndex: number) => (
                        (brand?.store_brands?.store_brand_product_type_data)?.map((product: any, index: number) => (
                            <tr key={`${brand?.id}_${index}`}>
                                {index === 0 ? (
                                    <td rowSpan={brand?.store_brands?.store_brand_product_type_data?.length} className={` border-right p-1 `} style={{ backgroundColor: "#fff" }}>
                                        <div className='d-flex align-items-center'>
                                            <div className='img-cont'>
                                                <CustomImages src={brand.brand_logo ? brand?.brand_logo : ""} imageHeight="0"/>
                                            </div>
                                            <div className='brandName p-2'>
                                                <span className="fw-500">{brand.brand_name}</span>
                                            </div>
                                        </div>
                                    </td>

                                ) : null}
                                <td className='text-center'>{product?.product_type?.name || product?.product_type_name}</td>
                                {/* <td className='text-center'>
                                    {product?.brand_margin}
                                </td> */}
                                <td className="text-center">
                                    {product?.brand_landing_cost}
                                </td>
                                <td className='text-center'>
                                    {product?.brand_discount}
                                </td>
                            </tr>
                        ))
                    ))}
                </tbody>

            </table> : <div className='mt-4 d-flex justify-content-center dark-gray'>
                <span>There are no records to display</span>
            </div>}
        </>
    )
}

export default Index