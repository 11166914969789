import React, { useState, useEffect, useContext } from 'react';
import Wrapper from '../../../components/common/Wrapper/Wrapper';
import organisms from '../../../components/organisms';
import service from '../../../api/services';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { convertArrayToKeyValue } from '../../../constants/utils';
import { HeaderHeading } from '../../../Context';

const { AddProduct } = organisms

const Index = () => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const { setHeader }: any = useContext(HeaderHeading);
    const [productType, setProductType] = useState([])
    const [deleteClick, setDeleteClick] = useState(false)
    const [brand, setBrand] = useState([])
    const [brandResponse, setBrandResponse] = useState([])
    const [productShape, setProductShape] = useState([])
    const [previewImageUrl, setPreviewImageUrl] = useState([])
    const [productMaterial, setProductMaterial] = useState([])
    const [productColor, setProductColor] = useState([])
    const [glassSizes, setGlassSizes] = useState<any>([])
    const [templeSizes, setTempleSizes] = useState<any>([])
    const [bridgeSizes, setBridgeSizes] = useState<any>([])
    let params = useParams();
    // let productEditID = params.id || ''
    const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    const [productData, setProductData] = useState<any>({
        isActive: false,
        product_id_display: '',
        modal_number: "",
        brand_color_code: '',
        brand_color_name: '',
        specx_colors: [],
        lens_colors: [],
        glass_size: '',
        temple_size: '',
        bridge_size: '',
        product_with_case: false,
        description: '',
        product_srp: '',
        features: [],
        rim_type: '',
        gender: [],
        warranty: '',
        weight: '',
        product_type_id: '',
        brand_id: '',
        product_materials: [],
        shapes: [],
        images: [],
        cover_index: 0,
        isPolarised: '',
        productDescription: ""
    })


    const getProductData = async () => {
        try {
            let productShapeResponse = await service.product.productShapeListingService({})
            let productMaterialResponse = await service.product.productMaterialListingService({})
            let productColorResponse = await service.product.productColorListingService({})
            let brandResponse = await service.product.brandListingService({})
            let sidesResponse = await service.product.sizeDetails()

            setGlassSizes(convertArrayToKeyValue(sidesResponse?.data.data.glass_size))
            setTempleSizes(convertArrayToKeyValue(sidesResponse?.data.data.temple_size))
            setBridgeSizes(convertArrayToKeyValue(sidesResponse?.data.data.bridge_size))
            setBrandResponse(brandResponse?.data?.data)
            setBrand(getDropdown(brandResponse?.data?.data, "id", "name"))
            setProductShape(getDropdown(productShapeResponse?.data?.data, "id", "name"))
            setProductMaterial(getDropdown(productMaterialResponse?.data?.data, "id", "name"))
            setProductColor(getDropdown(productColorResponse?.data?.data, "id", "name"))
        } catch (error) {
            console.error("error", error)
        }
    }
    const getDropdown = (response: [], key: string, name: string) => {
        let dropdown: any = []
        response && response.map((value: any) => {
            dropdown.push({
                "value": value[key] || value.product_type.id,
                "label": value[name] || value.brand_name || value.color_name || value.product_type.name,
                "code": value?.brand_code || value?.product_type_initials || value?.product_type?.product_type_initials
            }
            )
        })
        return dropdown
    }

    const addProducts = async () => {
        const formdata: any = new FormData();
        let singleKey: string[] = ['rim_type', 'warranty', 'weight', 'product_type_id',
            'brand_id', 'isPolarised', 'glass_size', 'temple_size', 'bridge_size']
        let multiKey: string[] = ['product_materials', 'features', 'specx_colors', 'lens_colors',
            'shapes', 'gender',]
        for (const key in productData) {
            if (productData.hasOwnProperty(key) && productData[key] !== undefined &&
                productData[key] !== '' && productData[key] !== null) {
                if (singleKey.includes(key)) {
                    formdata.append(key, productData[key].value)
                } else if (multiKey.includes(key)) {
                    productData[key].forEach((item: any) => formdata.append(key, item.value));
                } else if (key === 'images') {
                    productData?.images.forEach((image: any) => formdata.append('product_images', image))
                } else {
                    formdata.append(key, productData[key]);
                }
            }
        }
        try {
            let response = await service.product.add(formdata, { "Content-type": "multipart/form-data" })
            if (response.status === 201) {
                addToast('Product Add successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate('/product/list')
            }
        } catch (error: any) {
            console.log(error)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }


    useEffect(() => {
        setHeader("Add Product")
        getProductData()
    }, [])

    return (
        <Wrapper>
            <AddProduct
                productData={productData}
                setProductData={setProductData}
                productType={productType}
                brand={brand}
                productShape={productShape}
                productMaterial={productMaterial}
                productColor={productColor}
                glassSizes={glassSizes}
                templeSizes={templeSizes}
                bridgeSizes={bridgeSizes}
                imageUrl={previewImageUrl}
                brandResponse={brandResponse}
                setProductType={setProductType}
                getDropdown={getDropdown}
                addProducts={() => { addProducts() }}
            />
        </Wrapper>
    )
}
export default Index