import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { report } = Dictionary

const reportService = {
    sales: async (paramsObj:{}) => {
        return axios.get(BASE_URL + report.salesReport(), {params:paramsObj})
    },
    salesReturn: async (paramsObj:{}) => {
        return axios.get(BASE_URL + report.salesReturn(), {params:paramsObj})
    },
    inventory: async (paramsObj:{}) => {
        return axios.get(BASE_URL + report.inventoryReport(), {params:paramsObj})
    },
    gstOutput: async (paramsObj:{}) => {
        return axios.get(BASE_URL + report.gstOutputReport(), {params:paramsObj})
    },
    glAccountReport: async (payload: any) => {
        return axios.post(BASE_URL + report.glAccountReport(), payload)
    },
    getGeneralAccountList:async(paramsObj:{})=>{
        return axios.get(BASE_URL +report.generalAccountList(), {params:paramsObj})
    },
    getDailyActivityReport:async(paramsObj:{})=>{
        return axios.get(BASE_URL +report.dailyActivityReport(), {params:paramsObj})
    },
    inventoryAdditionalInvoiceWise: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.inventoryAdditionalInvoiceWiseReport(), { params: paramsObj })
    },
    inventoryAdditionalBarcodeWise: async (paramsObj: {}) => {
        return axios.get(BASE_URL + report.inventoryAdditionalBarcodeWiseReport(), { params: paramsObj })
    },
 
}
export default reportService
