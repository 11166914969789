import auth from './auth'
import staff from './staff'
import orderCardDetail from './orderCardDetail'
import procurementService from './procurementService'
import product from './product'
import inventory from './inventory'
import accounts from './accounts'
import store from './store'
import salesOrderService from './salesOrder'
import brandService from './brand'
import reportService from './report'
import DashboardService from './dashboard'
import SettingService from './setting'

const service = {
  auth,
  staff,
  orderCardDetail,
  procurementService,
  salesOrderService,
  product,
  inventory,
  accounts,
  store,
  brandService,
  reportService,
  DashboardService,
  SettingService,
}
export default service
