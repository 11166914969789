import React from 'react'
import DataTable from 'react-data-table-component';
import './CustomTable.scss'
const Index = ({
  columns, data,
  selectableRows,
  handleSelectedRow,
  paginationTotalRows,
  setCurrentPage,
  setLimitPerPage,
  pagination, className,conditionalRowStyles, rowHeight }: any) => {

  const customStyles:any = {
    headCells: {
      style: {
        position: 'sticky',
        top: 0,
        zIndex: 1, 
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        paddingTop: '15px',
        paddingBottom: '15px',
        background: '#D0F6FF',
        borderBottom: '1px solid #000',
        fontSize: '14px',

      },
    },
    rows: {
      style: {
        // height: '200px !important',
        minHeight:rowHeight ? rowHeight :'60px', // override the row height
      },

    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },

    },

  }
  return (
    <div className={`${className} mt-4`}>
    <div className="custom-data-table-container  ">
    <DataTable
      customStyles={customStyles}
      columns={columns}
      data={data}
      pagination={pagination}
      striped
      selectableRows={selectableRows}
      onSelectedRowsChange={handleSelectedRow}
      selectableRowsHighlight
      // theme="dark"
      paginationServer
      paginationRowsPerPageOptions={[20, 50, 100,200]}
      paginationTotalRows={paginationTotalRows}
      onChangeRowsPerPage={(limit) => setLimitPerPage(limit)}
      onChangePage={(page) => setCurrentPage(page)}
      conditionalRowStyles={conditionalRowStyles}
    />
    </div>
    </div>
  )
}

export default Index