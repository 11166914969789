import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import constant from '../../../../constants/constant'
import { Default_Page_Limit } from "../../../../constants/defaultValues"
import organisms from '../../../../components/organisms'
import { HeaderHeading } from '../../../../Context'


const { OrderReceived } = organisms

const Index = () => {
  const [initialLoading, setInitialLoading] = useState(false)
  const [orderReceivedList, setOrderReceivedList] = useState<[] | undefined>();
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();
  const { setHeader }: any = useContext(HeaderHeading);
  const [createdTo, setCreatedTo] = useState()
  const [createdFrom, setCreatedFrom] = useState()


  const viewCancelledList = async () => {
    try {
      const response = await service.procurementService.OrderReceivedList({
        "page": currentPage,
        "search": search,
        "limit": limitPerPage,
        "status": constant.PROCUREMENT_ITEM_STATUS.DELIVERED,
        "created_from": createdFrom,
        "created_to": createdTo,
      })
      if (response.status === 200) {
        setOrderReceivedList(response?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setHeader("Procurement - Order Received")
    if (initialLoading) {
      viewCancelledList()
    } else {
      setInitialLoading(true)
    }
  }, [search, currentPage])

  useEffect(() => {
    setCurrentPage(1)
    if (currentPage == 1) {
      viewCancelledList()
    }
  }, [limitPerPage])

  useEffect(() => {
    if (createdTo && createdFrom) {
      viewCancelledList()
    }
  }, [createdFrom, createdTo])
  return (
    <Wrapper>
      <OrderReceived
        orderReceivedList={orderReceivedList}
        setSearch={setSearch}
        limit={limitPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setLimitPerPage={setLimitPerPage}
        setCreatedTo={setCreatedTo}
        setCreatedFrom={setCreatedFrom}
      />
    </Wrapper>
  )
}

export default Index