import React from 'react'
import PlaceHolderImage from '../../../../assets/Images/placeholderimg.png'
import { findConstantLabel } from "../../../../constants/utils";
import constant from '../../../../constants/constant';
import moment from 'moment'
import atoms from '../../../atoms';
import { cancelledPageProp, cancelledList } from './interface';

const { Pagination, Search, Table } = atoms

const Index = ({ orderCancelledList, setSearch, currentPage, setCurrentPage, setLimitPerPage, limit }: cancelledPageProp) => {

    const columns = [
        { name: 'Product ID', selector: (row: cancelledList) => row?.product?.product_id_display,minWidth: '150px',maxWidth:"160px" },
        { name: 'Brand', selector: (row: any) => row?.product?.brand?.brand_name || '-', minWidth:"120px" ,maxWidth:"150px"},
        {
            name: 'Cover Photo',
            // selector: (row: any) => `${row.product_type_initials}`,
            cell: (row: any) => {
                const style = {
                    height: "75%",
                    width: "100%",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${row?.product?.images?.length>0 ? row?.product?.images[0] : PlaceHolderImage})`
                }
                return <div style={style}></div>;
            },center:true,maxWidth:"150px"
        },
        // { name: 'Cover Photo', selector: (row: cancelledList) => <img src={row?.product?.images[0] ? row?.product?.images[0] : PlaceHolderImage} className='w-50 rounded object-contain' /> },
        { name: 'Model No.', selector: (row: any) => row?.product?.modal_number || '-', maxWidth:"150px" },
        { name: 'Color Code', selector: (row: any) => row?.product?.brand_color_code || '-', maxWidth:"150px" },
        { name: 'With Case', selector: (row: any) => row?.product?.product_with_case ? 'Yes' : 'No' || '-', maxWidth:"150px" },
        { name: 'Date', selector: (row: cancelledList) => moment(row?.createdAt).format('DD/MM/YYYY, H:mm:ss') || '-', minWidth:"150px",maxWidth:"180px" },
        { name: 'Product Type', selector: (row: cancelledList) => row?.product?.product_type?.name, maxWidth:"150px" },
        { name: 'Rim Type', selector: (row: cancelledList) => findConstantLabel(constant.PRODUCT_RIM_TYPE, row?.product?.rim_type) || '-', maxWidth:"150px" },
        { name: 'Glass Size', selector: (row: cancelledList) => row?.product?.glass_size || '-',maxWidth:"150px" },
        { name: 'Cancelled By', selector: (row: cancelledList) => row?.cancelled_by || '-', minWidth:"150px" },

    ]

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6"><Search searchCallBack={(e: string) => { setSearch(e) }} className="w-100" /></div>
            </div>
            <div>
                <Table
                    columns={columns}
                    AdditionalClassName="table-height-50"
                    data={orderCancelledList?.data}
                />
            </div>
            <div>
                <Pagination
                    totalCount={orderCancelledList?.count}
                    currentPage={currentPage}
                    totalPages={orderCancelledList?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
        </>
    )
}

export default Index