import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { salesOrder } = Dictionary

const salesOrderService = {
    salesOrderList: async (paramsObj: any) => {
        return axios.get(BASE_URL + salesOrder.getSalesOrder(),{ params: paramsObj })
    },

}
export default salesOrderService
