import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { Procurement } = Dictionary

const procurementService = {
  viewOrderHistory: async (paramsObj: any) => {
    return axios.get(BASE_URL + Procurement.viewOrderHistory() ,{ params: paramsObj })
  },
  orderHistoryItems:async(id:string)=>{
    return axios.get(BASE_URL + Procurement.orderHistoryItems(id))
  },
  orderHistoryItemCancel: async(id:string, payload:{})=>{
    return axios.post(BASE_URL + Procurement.cancelApi(id), payload)
  },
  OrderReceivedList: async (paramsObj: any) => {
    return axios.get(BASE_URL + Procurement.OrderReceivedCancelledList(),{ params: paramsObj })
  },
  OrderCancelledList: async (paramsObj: any) => {
    return axios.get(BASE_URL + Procurement.OrderReceivedCancelledList(),{ params: paramsObj })
  },
  
  transferRequestedList: async (paramsObj: any) => {
    return axios.get(BASE_URL + Procurement.transferRequestedList() ,{ params: paramsObj })
  },
  transferRequestedListItemCancel: async(id:string, payload:{})=>{
    return axios.post(BASE_URL + Procurement.cancelApi(id), payload)
  },
  transferRequestCancelledList: async (paramsObj: any) => {
    return axios.get(BASE_URL + Procurement.transferRequestCancelledMoveOutList(),{ params: paramsObj })
  },
  transferRequestMoveOutList: async (paramsObj: any) => {
    return axios.get(BASE_URL + Procurement.transferRequestCancelledMoveOutList(),{ params: paramsObj })
  },
}
export default procurementService
