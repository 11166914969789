import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import molecules from "../../molecules";

import { Link, useNavigate } from "react-router-dom";
import atoms from "../../atoms";

const { Button, Table, Search, Pagination } = atoms
const { ConfirmationModal } = molecules
const Index: React.FC<any> = ({ setLimitPerPage, setCurrentPage, limitPerPage, setSearch, staffData, currentPage, _handleDelete }) => {
    const navigate = useNavigate()
    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const [subAdminId, setSubAdminId] = useState<string>("")

    const columns = [
        { name: 'User Id', selector: (row: any) => row.user?.login_id, sortable: true},
        { name: 'Name', selector: (row: any) => row.user.username, sortable: true },
        { name: 'Role Type', selector: (row: any) => row.user.role_type},
        { name: 'Contact No.', selector: (row: any) => row.user.mobile },
        { name: 'Email Address', selector: (row: any) => row.user.email},
        { name: 'Action', selector: (row: any) => row.action, minWidth: '270px' },
    ]
    const _handleDeletePopup = (id: string) => {
        setSubAdminId(id)
        setDeletePopup(!deletePopup)
    }
    return (
        <>
            <div className="row">
                <div className="col-md-5">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} className="w-100" /></div>
                <div className="col-md-7 mt-md-0 mt-3 text-end">
                    <Button name={"Add Staff"}
                        className=''
                        onClick={() => navigate('/staff/add')}
                    />
                </div>
            </div>
            <div className="mt-md-5 mt-3">
                {staffData?.data ? (
                    <Table columns={columns}
                        // pagination
                        // paginationTotalRows={staffData.count}
                        AdditionalClassName="table-height-50"
                        setLimitPerPage={(e: number) => setLimitPerPage(e)}
                        setCurrentPage={(e: number) => setCurrentPage(e)}
                        currentPage={currentPage}
                        data={staffData?.data?.map((item: any, index: number) => {
                            return ({
                                ...item,
                                action:
                                    <>
                                        <Link
                                            to={`/staff/edit/${item.id}`}
                                        >
                                            <Button className="table-edit-button fs-14 fw-400">
                                                <div className='d-flex align-items-center'>
                                                    <FiEdit />
                                                    <span className='ms-2 mb-0'>Edit</span>
                                                </div>
                                            </Button>
                                        </Link>
                                        <Button className="table-delete-button fs-14 fw-400 ms-3"
                                            onClick={() => _handleDeletePopup(item.id)}>
                                            <div className='d-flex align-items-center'>
                                                <RiDeleteBin6Line />
                                                <span className='ms-2 mb-0'>Delete</span>
                                            </div>
                                        </Button>
                                    </>
                            })
                        })
                        }
                    />) : ""

                }
            </div>
            <Pagination
                totalCount={staffData?.count}
                currentPage={currentPage}
                totalPages={staffData?.totalPages}
                onChangePage={(page: any) => setCurrentPage(page)}
                setLimit={(e: number) => setLimitPerPage(e)}
                limit={limitPerPage}
            />

            {deletePopup && <ConfirmationModal
                show={deletePopup}
                handleConfirmItem={() => { _handleDelete(subAdminId); setDeletePopup(!deletePopup) }}
                onHide={() => setDeletePopup(!deletePopup)}
                message={'delete this Account'} />}
        </>
    )
}

export default Index