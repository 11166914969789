import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { accounts } = Dictionary

const AccountsServices = {

    ViewEntries: async (paramsObj: any) => {
        return axios.get(BASE_URL + accounts.getEntries(), { params: paramsObj })
    },

    ViewGeneralAccountList: async (paramsObj: any) => {
        return axios.get(BASE_URL + accounts.getGeneralAccountList(), { params: paramsObj })
    }
}
export default AccountsServices
