import { numberWithCommas } from "../../../../constants/utils";
import moment from "moment";
import atoms from "../../../atoms";

const { Table, Pagination } = atoms

const Index = ({ listData, limitPerPage,
    currentPage, setCurrentPage, setLimitPerPage }: any) => {

    const columns = [
        { name: 'Account Number', selector: (row: any) => `${row?.gl_number ? row?.gl_number : "--"}`,maxWidth:"150px" },
        { name: 'Date', selector: (row: any) => row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "-",maxWidth:"120px" },
        { name: 'Name', selector: (row: any) => row?.gl_account_name, sortable: true },
        { name: 'Account Balance', selector: (row: any) => `${numberWithCommas(parseFloat(Number(row.balance || 0)?.toFixed(2)))}`, maxWidth:"150px" },
        { name: 'Account Sub Type', selector: (row: any) => row?.account_sub_type?.name, sortable: true },
    ]


    return (
        <>
            {listData?.data?.length > 0 && <div>
                <Table
                    columns={columns}
                    AdditionalClassName="table-height-50"
                    data={listData?.data}
                />
            </div>}
            <div>
                <Pagination
                    totalCount={listData?.count}
                    currentPage={currentPage}
                    totalPages={listData?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limitPerPage}
                />
            </div>
        </>
    )
}

export default Index