import React from 'react';
// import { PlaceHolderImage } from '../../../constants/defaultValues';
import  BrandPlaceHolder  from '../../../assets/Images/placeholderimg.png';
import { ImagePropInterface } from './interface';

const Image = ({ src, onClick, className,style }: ImagePropInterface) => {
  return (
    <img onClick={onClick} src={src ? src : BrandPlaceHolder} alt='' className={className} style={style} />
  );
};

export default Image;
