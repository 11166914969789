import Button from './Button';
import Image from './Image';
import CustomImages from './CustomImage';
import Search from './Search';
import Select from './Select';
import Table from './Table';
import Input from './Input';
import RadioInput from './RadioInput';
import TextArea from './TextArea';
import CustomSearch from './CustomSearch';
import Pagination from './Pagination';
import AvailableBadge from './AvailableBadge';
import Badges from './Badges'
import CustomTable from "./CustomTable"

const atoms = {
    Button,
    Image,
    CustomImages,
    Search,
    Select,
    Table,
    Input,
    RadioInput,
    TextArea,
    CustomSearch,
    Pagination,
    AvailableBadge,
    Badges,
    CustomTable
}
export default atoms