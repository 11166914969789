import React from 'react'
import { InputInterface } from './interface'
import './Input.scss'
const Input = ({ id, type, name, defaultValue, onChange, errors, className, placeholder, label, htmlFor, value, required, ref,rows }: InputInterface) => {
    const handleKeyDown = (event: any) => {
        if (type === 'number' && (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'e')) {
            event.preventDefault();
        }
    }
    return (
        <div className="form-group">
            {label && <label className='inputs-label mb-2 fs-16 fw-600  secondary-color' htmlFor={htmlFor}>{label}</label>}
            <textarea
                id={id}
                className={`${type === "radio" ? '' : "inputs form-control "} shadow-none ${className}`}
                name={name}
                defaultValue={defaultValue}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                required={required}
                ref={ref}
                onKeyDown={handleKeyDown}
                rows={rows}
            />
            {errors && <span className="error-text">{errors}</span>}
        </div>
    )
}

export default Input