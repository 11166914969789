import React from 'react'
import atoms from '../../../atoms'
import moment from 'moment'
import { AiOutlineEye } from "react-icons/ai";
import constant from '../../../../constants/constant';
import { numberWithCommas } from '../../../../constants/utils'
import { MyComponentProps } from "./interface"
import { useNavigate } from 'react-router-dom'

const { Search, Button, Table, Pagination } = atoms
const Index :React.FC<MyComponentProps>  = ({ setSearch, orderHistory, setLimitPerPage, setCurrentPage, currentPage, limit, count, totalPages }) => {
    const navigate = useNavigate()
    const columns = [
        { name: 'Procurement ID', selector: (row: any) => row?.procurement_display_id,minWidth:"120px" },
        { name: 'Date', selector: (row: any) => moment(row?.createdAt).format('DD/MM/YYYY'), minWidth:"120px"},
        {
            name: 'Total Number Of Items', selector: (row: any) =>
                <>
                    <div className='font-weight-light'>
                        <p className='mb-1 fw-400' >Requested : {row?.groupData && row?.groupData?.reduce((acc:any, row:any) => acc.concat(row), []).length}</p>
                        <p className='mb-1 fw-400'>Pending : {row?.groupData[constant.PROCUREMENT_ITEM_STATUS.PENDING] ? row?.groupData[constant.PROCUREMENT_ITEM_STATUS.PENDING]?.length : 0}</p>
                        <p className='mb-1 fw-400'>In-process :{row?.groupData[constant.PROCUREMENT_ITEM_STATUS.TO_BE_DELIVERED] ? row?.groupData[constant.PROCUREMENT_ITEM_STATUS.TO_BE_DELIVERED]?.length : 0}</p>
                        <p className='mb-1 fw-400'>Delivered : {row?.groupData[constant.PROCUREMENT_ITEM_STATUS.DELIVERED] ? row?.groupData[constant.PROCUREMENT_ITEM_STATUS.DELIVERED]?.length : 0}</p>
                        <p className='mb-1 fw-400'>Cancelled :{row?.groupData[constant.PROCUREMENT_ITEM_STATUS.CANCEL] ? row?.groupData[constant.PROCUREMENT_ITEM_STATUS.CANCEL]?.length : 0}</p>
                    </div>
                </>,minWidth:"120px"
        },
        {
            name: 'Total Landing Cost', selector: (row: any) => <>
                <div>
                    <p className='mb-1 ' >Requested  ₹{numberWithCommas(parseFloat(handleTotalLandingCost(row.groupData)))}</p>
                    <p className='mb-1 fw-400'>Pending  ₹{numberWithCommas(parseFloat(handleCalculation(row.groupData[constant.PROCUREMENT_ITEM_STATUS.PENDING])?.toFixed(2)))}</p>
                    <p className='mb-1 '>In-process ₹{numberWithCommas(parseFloat(handleCalculation(row.groupData[constant.PROCUREMENT_ITEM_STATUS.TO_BE_DELIVERED])?.toFixed(2)))}</p>
                    <p className='mb-1 '>Delivered ₹{numberWithCommas(parseFloat(handleCalculation(row.groupData[constant.PROCUREMENT_ITEM_STATUS.DELIVERED])?.toFixed(2)))}</p>
                    <p className='mb-1 '>Cancelled ₹{numberWithCommas(parseFloat(handleCalculation(row.groupData[constant.PROCUREMENT_ITEM_STATUS.CANCEL])?.toFixed(2)))}</p>
                </div>
            </>,minWidth:"150px"
        },
        { name: 'Action', selector: (row: any) => row.action, minWidth:"120px"},

    ]

    const handleCalculation = (data: any) => {
        if (data) {
            const {totalLandingCost} = data?.reduce((previousValue:any, currentValue:any) => {
                previousValue.totalLandingCost += currentValue?.landing_cost || 0;
                return previousValue;
            }, {totalLandingCost: 0 });
            return totalLandingCost
        }else{
            return 0
        }
    }
    const handleTotalLandingCost = (data: any) => {
        let totalLandingCost = 0
        data?.forEach((Element:any)=>{
            totalLandingCost += parseFloat(handleCalculation(Element))
        })
        return totalLandingCost.toFixed(2)
    }

    return (
        <div>
            <div className="row align-items-center">
                <div className="col-md-6">
                    <Search searchCallBack={(searchQuery: string) => {setSearch(searchQuery) }} className="w-md-50 w-100"/>
                </div>
            </div>
            <div>
                <Table
                    AdditionalClassName="add-inventory-tables"
                    columns={columns}
                    data={orderHistory?.map((item: any) => {
                        return ({
                            ...item,

                            action:

                                <div style={{ overflow: 'unset', textOverflow: 'unset' }}>
                                    <Button className="table-edit-buttons fs-14 fw-500"
                                        onClick={() => navigate(`/procurement/order/history/product-detail/${item.id}`)}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <AiOutlineEye className='fs-20' />
                                            <span className='ms-2 mb-0'>View</span>
                                        </div>
                                    </Button>
                                </div>

                        })
                    })}
                />
            </div>
            {orderHistory && <div>
                <Pagination
                    totalCount={count}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>}
        </div>
    )
}

export default Index