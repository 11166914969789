import React from 'react'
import './Search.scss'
import { FiSearch } from "react-icons/fi";
import { Link } from 'react-router-dom';

const Index = ({ searchCallBack, placeholder,className }: any) => {
    let debounce: any;
    const handleSearch = (e: any) => {
        clearTimeout(debounce)
        debounce = setTimeout(() => {
            searchCallBack(e)
        }, 1000);
    }
    return (
        <>
            <div className="position-relative">
                <span className='position-absolute search-icon'><FiSearch /></span>
                <input
                    className={`search ${className ? className : "w-50"} search-style`}
                    autoComplete="off"
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={placeholder ? placeholder : 'Search'}
                    // defaultValue={searchKeyword}
                    onChange={(e) => {
                        handleSearch(e.target.value);
                    }}
                />
            </div>
        </>

    )
}

export default Index