import React from 'react'
import atoms from '../../atoms'
import { numberWithCommas } from '../../../constants/utils'
import { useNavigate } from 'react-router'
const { Button } = atoms
const Index = ({ dashboardDetails }: any) => {
  const navigate = useNavigate()

  return (
    <div className="">
      <div className="row">
        <div className="col-md-4">
          <div className="card-dashboard mx-auto cursor-pointer card-dashboard-width"
            onClick={() => navigate('/inventory/detail')}>
            <div className="card-body">
              <div className='fs-md-24 fs-20 fw-600'>Inventory</div>
              <div className="d-flex justify-content-between mt-md-3 mt-1">
                <div>
                  <p className="card-text fs-md-16 fs-14 fw-400 ">Total Store Inventory</p>
                  <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails.store_inventory_count} | ₹ {numberWithCommas(parseInt(Number(dashboardDetails.store_inventory_landing_cost).toFixed(0)))} </p>
                  <p className="text-muted fs-10 fw-500 mb-2"> Quantity | Amount (L. Cost) </p>
                </div>
                <div className="icon-container ">
                  <i className="fas fa-shopping-cart"></i>
                </div>
              </div>
              <hr className='line-margin'></hr>
              <div>
                <p className="card-text fs-md-16 fs-14 fw-400 ">Total Salable Inventory</p>
                <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails.saleable_inventory_count} | ₹ {numberWithCommas(parseInt(Number(dashboardDetails.saleable_inventory_landing_cost)?.toFixed(0)))} </p>
                <p className="text-muted fs-10 fw-500"> Quantity | Amount (L. Cost) </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card-dashboard mx-auto cursor-pointer card-dashboard-width"
            onClick={() => navigate('/order/detail')}>
            <div className="card-body">
              <div className='fs-md-24 fs-20 fw-600'>Sales Order</div>
              <div className="d-flex justify-content-between mt-md-3 mt-1">
                <div>
                  <p className="card-text fs-md-16 fs-14 fw-400 ">Today</p>
                  <p className="card-title fs-md-14 fs-12 fw-600 mb-md-1">{dashboardDetails.current_day_orders}/ ₹{numberWithCommas(parseInt(Number(dashboardDetails.current_day_orders_value).toFixed(0)))} </p>
                </div>
                <div className="icon-container ">
                  <i className="fas fa-shopping-cart"></i>
                </div>
              </div>
              <hr className='line-margin'></hr>
              <div>
                <p className="card-text fs-md-16 fs-14 fw-400 ">This Month</p>
                <p className="card-title fs-md-14 fs-12 fw-600 mb-2">{dashboardDetails.current_month_orders} / ₹{numberWithCommas(parseInt(Number(dashboardDetails.current_month_orders_value).toFixed(0)))}</p>
              </div>
              <hr className='line-margin'></hr>
              <div>
                <p className="card-text fs-md-16 fs-14 fw-400 ">This year</p>
                <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails.current_financial_year_orders} / ₹{numberWithCommas(parseInt(Number(dashboardDetails.current_financial_year_orders_value).toFixed(0)))}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card-dashboard mx-auto card-dashboard-width">
            <div className="card-body">
              <div className='fs-md-24 fs-20 fw-600'>Receipts</div>
              <div className="d-flex justify-content-between mt-md-3 mt-1">
                <div>
                  <p className="card-text fs-md-16 fs-14 fw-400 ">Today</p>
                  <p className="card-title fs-md-14 fs-12 fw-600 "> ₹ {numberWithCommas(parseInt(Number(dashboardDetails.current_day_cash)?.toFixed(0)))} / ₹ {numberWithCommas(parseInt(Number(dashboardDetails.current_day_card)?.toFixed(0)))} / ₹ {numberWithCommas(dashboardDetails.current_day_upi)} </p>
                  <p className="text-muted fs-10 fw-500 mb-2"> Cash | Card | UPI </p>
                </div>
                <div className="icon-container ">
                  <i className="fas fa-shopping-cart"></i>
                </div>
              </div>
              <hr className='line-margin'></hr>
              <div>
                <p className="card-text fs-md-16 fs-14 fw-400 ">This Month</p>
                <p className="card-title fs-md-14 fs-12 fw-600 "> ₹ {numberWithCommas(parseInt(Number(dashboardDetails.current_month_cash)?.toFixed(0)))} / ₹ {numberWithCommas(parseInt(Number(dashboardDetails.current_month_card)?.toFixed(0)))} / ₹ {numberWithCommas(parseInt(Number(dashboardDetails.current_month_upi)?.toFixed(0)))}</p>
                <p className="text-muted fs-10 fw-500 mb-2"> Cash | Card | UPI </p>
              </div>
              <hr className='line-margin'></hr>
              <div>
                <p className="card-text fs-md-16 fs-14 fw-400 ">This year</p>
                <p className="card-title fs-md-14 fs-12 fw-600 "> ₹ {numberWithCommas(parseInt(dashboardDetails.current_year_cash?.toFixed(0)))} / ₹ {numberWithCommas(parseInt(Number(dashboardDetails.current_year_card)?.toFixed(0)))} / ₹ {numberWithCommas(parseInt(Number(dashboardDetails.current_year_upi)?.toFixed(0)))}</p>
                <p className="text-muted fs-10 fw-500"> Cash | Card | UPI </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="card-dashboard mx-auto cursor-pointer card-dashboard-width"
            onClick={() => navigate('/accounts/gl')}>
            <div className="card-body">
              <div className='fs-md-24 fs-20 fw-600'>Accounts</div>
              <div className="d-flex justify-content-between mt-md-3 mt-1">
                <div>
                  <p className="card-text fs-md-16 fs-14 fw-400 ">Deposit A/c</p>
                  <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails.deposit_account_details?.gl_number} | ₹ {numberWithCommas(parseFloat(Number(dashboardDetails.deposit_account_details?.balance)?.toFixed(0)))} </p>
                  <p className="text-muted fs-10 fw-500 mb-2"> GL Number | Balance </p>
                </div>
                <div className="icon-container ">
                  <i className="fas fa-shopping-cart"></i>
                </div>
              </div>
              <hr className='line-margin'></hr>
              <div>
                <p className="card-text fs-md-16 fs-14 fw-400 ">Current A/c</p>
                <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails.current_account_details?.gl_number} | ₹ {numberWithCommas(parseFloat(Number(dashboardDetails.current_account_details?.balance)?.toFixed(0)))}</p>
                <p className="text-muted fs-10 fw-500 mb-2"> GL Number | Balance </p>

              </div>
              <hr className='line-margin'></hr>
              <div>
                <p className="card-text fs-md-16 fs-14 fw-400 ">Inventory A/c</p>
                <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails.inventory_account_details?.gl_number} | ₹ {numberWithCommas(parseInt(Number(dashboardDetails.inventory_account_details?.balance)?.toFixed(0)))}</p>
                <p className="text-muted fs-10 fw-500 mb-2"> GL Number | Balance </p>

              </div>
              <hr className='line-margin'></hr>
              <div>
                <p className="card-text fs-md-16 fs-14 fw-400 ">Net Balance Payable</p>
                <p className="card-title fs-md-14 fs-12 fw-600 "> ₹ {numberWithCommas(parseInt((Number(dashboardDetails.deposit_account_details?.balance) + Number(dashboardDetails.current_account_details?.balance) + Number(dashboardDetails.inventory_account_details?.balance)).toFixed(0)))}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4"></div>
      </div>
    </div>
  )
}

export default Index