import React from "react";
import { PriceRangeProp } from "./interface";
import Slider from "@mui/material/Slider";
import atoms from "../../atoms";

const { Input }= atoms

const PriceRange = (props: PriceRangeProp) => {
    const { range, onChange, handleInputPrice } = props
    return (
        <>
            <div className="d-flex py-3 mt-1" style={{ padding: "20px" }}>
                <Slider value={range} onChange={onChange} min={0} max={50000} valueLabelDisplay="auto" />
            </div>
            <div className="py-3 d-flex" style={{ padding: "10px" }}>
                <div className="mx-1 fs-16 text-secondary">
                    Min: 
                    <Input
                             id="minPrice"
                             type="number"
                             name="minPrice"
                             onChange={handleInputPrice}
                             placeholder="Max Price"
                             value={range[0]}
                             className="price-range-height"
                    />
                </div>
                <div className="mx-1 fs-16 text-secondary">
                    Max:  
                      <Input
                             id="minPrice"
                             type="number"
                             name="maxPrice"
                             onChange={handleInputPrice}
                             placeholder="Max Price"
                             value={range[1]}
                             className="price-range-height"
                    />
                </div>

            </div>
        </>
    )
}

export default PriceRange