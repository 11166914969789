import React from 'react'
import molecules from '../../../molecules'
const { ProductCard } = molecules

const Index = ({ productList }: any) => {
    return (
        <div className="row">
            {productList?.length > 0 ? productList?.map((item: any, index: number) => (
                <ProductCard productData={item} key={index} />
            )) :
            <div className='justify-content-center d-flex mt-5 '>
                <span className='fs-22 '>No Product Available</span>
            </div>
            }
        </div>
    )
}

export default Index