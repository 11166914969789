import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { staff } = Dictionary

const staffDetail = {
  view: async (paramsObj: any) => {
    return axios.get(BASE_URL + staff.staff(), { params: paramsObj })
  },
  add: async (data: {}) => {
    return axios.post(BASE_URL + staff.staff(), data)
  },
  delete: async (id: string) => {
    return axios.delete(BASE_URL + staff.staffById(id))
  },
  edit: async (id: string, data: {}) => {
    return axios.put(BASE_URL + staff.staffById(id), data)
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + staff.staffById(id))
  },
  changePassword:async(id:string, payload:{})=>{
    return axios.put(BASE_URL + staff.changeStaffPassword(id), payload)
  },
  structuredPermission: async () => {
    return axios.get(BASE_URL + staff.structuredPermission())
},
}
export default staffDetail
