import React, { useState, useEffect } from 'react';
import { limitOptions } from '../../../constants/defaultValues';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import './Pagination.scss'

const Pagination = ({
    totalCount,
    currentPage,
    totalPages,
    onChangePage,
    limit,
    setLimit
}: any) => {
    const pageNumbers = [];

    const [tableRowCount, setTableRowCount] = useState({
        firstRow: 1,
        lastRow: Math.min(limit, totalCount)
    });
    useEffect(() => {
        updateRowCount(1, limit);
    }, [totalCount, limit])
    const updateRowCount = (newFirstRow: number, newLastRow: number) => {
        setTableRowCount({
            firstRow: Math.max(1, newFirstRow),
            lastRow: Math.min(totalCount, newLastRow)
        });
    };
    const paginationButton = (n: string) => {
        const increment = n === 'next' ? limit : -limit;
        let newFirstRow = tableRowCount.firstRow + increment;
        let newLastRow = tableRowCount.lastRow + increment;
        if (newLastRow < limit) {
            newLastRow = limit
        }
        updateRowCount(newFirstRow, newLastRow);
    };

    const paginationNumberButton = (currentPage: number) => {
        let newFirstRow = 1 + (currentPage - 1) * limit
        let newLastRow = Math.min(currentPage * limit, totalCount)
        updateRowCount(newFirstRow, newLastRow);
    }


    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const generatePageNumbers = () => {
        const pageNumbers = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 5; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
                pageNumbers.push(totalPages);
            } else if (currentPage >= totalPages - 2) {
                pageNumbers.push(1);
                pageNumbers.push('...');
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pageNumbers.push(i);
                }
            } else {
                pageNumbers.push(1);
                pageNumbers.push('...');
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
                pageNumbers.push(totalPages);
            }
        }

        return pageNumbers;
    };
    return (
        <>
            {totalPages ?
                <div className='row align-items-center custom-pagination px-md-3'>

                    {/* <div className="col-12 col-md-6 custom-limit-dropdown" >
                        <select
                            className='count-style'
                            value={limit}
                            onChange={(e) => {
                                setLimit(Number(e.target.value));
                            }}
                        >
                            {limitOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div> */}

                    <div className="col-12 d-flex px-3 gap-4 align-items-center justify-content-center overflow-md-hidden overflow-auto">
                        {(window?.innerWidth > 768 && totalCount) &&
                            <button
                                disabled={currentPage > 0}
                                className="align-items-center border-0 btn d-flex justify-content-center"
                                style={{ minWidth: "120px", marginLeft: window?.innerWidth < 768 ? "310px" : "" }}
                            >
                                {tableRowCount.firstRow} - {tableRowCount.lastRow} of {totalCount}
                            </button>

                        }
                        {/* {totalCount &&
                        <div>
                            <span className='fs-14 fw-500'>{tableRowCount.firstRow}</span> - <span className='fs-14 fw-500'>{tableRowCount.lastRow}</span> of <span className='fs-14 fw-500'>{totalCount}</span>
                        </div>
                        } */}
                        <button
                            onClick={() => {
                                onChangePage(currentPage - 1)
                                paginationButton('previous')
                            }}
                            disabled={currentPage === 1}
                            className="align-items-center border-0 btn d-flex justify-content-center"
                        >
                            <IoIosArrowBack />
                            Previous
                        </button>
                        {generatePageNumbers().map((pageNumber, index) => (
                            <span
                                key={index}
                                className={`${pageNumber === currentPage ? 'active-background' : ''} cursor-pointer`}
                                onClick={() => {
                                    if (pageNumber !== '...') {
                                        onChangePage(pageNumber);
                                        paginationNumberButton(pageNumber)
                                    }
                                }}
                            >
                                {pageNumber}
                            </span>
                        ))}
                        <button
                            className="align-items-center border-0 btn d-flex justify-content-center"
                            onClick={() => {
                                onChangePage(currentPage + 1)
                                paginationButton('next')
                            }}
                            disabled={currentPage >= totalPages}
                        >
                            Next
                            <IoIosArrowForward />
                        </button>
                        {window?.innerWidth > 768 && <select
                            className='count-style'
                            value={limit}
                            onChange={(e) => {
                                setLimit(Number(e.target.value));
                            }}
                        >
                            {limitOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>}
                    </div>

                </div>
                : ""
            }
        </>
    );
};

export default Pagination;
