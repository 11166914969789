import React, { useEffect, useRef, useState } from 'react';
import CopyIcon from '../../../assets/Images/copy-icon.svg'
import CloseIcon from '../../../assets/Images/close.svg'
import { validationRules } from './validation';
import atoms from '../../atoms';
import molecules from '../../molecules';
import constant from '../../../constants/constant';

const { Image, Select, Input, Button, RadioInput }: any = atoms
const { TopBar }: any = molecules

const Index = ({ productType, brand, productShape, productMaterial, productColor, productData, addProducts
    , setProductData, imageUrl, brandResponse, setProductType, getDropdown, glassSizes,
    templeSizes, bridgeSizes }: any) => {
    const fileInputRef = useRef<any>(null);
    const [previewImage, setPreviewImage] = useState([])
    const [errors, setErrors] = useState<any>({});
    const [imageFileStore, setImageFileStore] = useState<any>()

    const _handleImageChange = (event: any) => {
        setImageFileStore(event)
        const files = event.target.files;
        const updatedImages: any = [...productData.images];
        const updateImageUrl: any = [...previewImage];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const imageUrl = URL.createObjectURL(file);
            updateImageUrl.push(imageUrl)
            updatedImages.push(file);
        }
        setPreviewImage(updateImageUrl)
        setProductData({ ...productData, images: updatedImages })
        setErrors((prevErrors: any) => ({ ...prevErrors, images: "" }));
    };
    const handleImageRemove = (image: any, index: number) => {
        if (fileInputRef && fileInputRef.current && fileInputRef.current.value == null) {
            fileInputRef.current.value = '';
            const tempStoreInputData = imageFileStore
            tempStoreInputData.target.value = ''
            setImageFileStore(tempStoreInputData)
        }
        let tempPreview = [...previewImage]
        let tempStoreImage = productData?.images
        tempPreview.splice(index, 1)
        setPreviewImage(tempPreview)
        tempStoreImage.splice(index, 1)
        setProductData({ ...productData, images: tempStoreImage })

        // !isDuplicateValue && imagesDelete(image)

    }
    const _handelCurrentCover = (current: number) => {
        setProductData({ ...productData, cover_index: current })
    }
    useEffect(() => {
        setPreviewImage(imageUrl)
    }, [imageUrl])

    const handleChange = (name: string, value: any) => {
        setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
        if (name === 'modal_number') {
            const containsUnderscore = /_/;
            if (containsUnderscore.test(value)) {
                setErrors((prevErrors: any) => ({
                    ...prevErrors,
                    [name]: "Modal number not allow underscores."
                }));
                return
            }
        }
        setProductData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));

    };

    const handleBrand = (value: any) => {
        if (brandResponse && value) {
            setProductData((prev: any) => ({ ...prev, product_type_id: "" }));
            const filteredData: any = brandResponse?.filter((item: any) => item?.id === value?.value);
            setProductType(getDropdown(filteredData[0]?.product_types))
        }
    }
    const validate = () => {
        let newErrors: any = {};
        for (const field in validationRules) {
            if (validationRules.hasOwnProperty(field)) {
                if (validationRules[field]?.required && !productData[field]) {
                    newErrors[field] = "This field is required.";
                } else if ((validationRules[field]?.label?.required) && (!productData[field]?.label)) {
                    newErrors[field] = "Please Select field";
                } else if ((validationRules[field][0]?.label?.required) && (!productData[field][0]?.label)) {
                    newErrors[field] = "Please Select field";
                }
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        const isValid = validate();
        if (isValid) {
            addProducts()
        }
    };

    useEffect(() => {
        getProductId()
        getDescription()
    }, [productData.brand_id, productData?.product_type_id, productData?.modal_number, productData?.rim_type, productData?.shapes, productData?.specx_colors, productData?.brand_color_name,
    productData?.brand_color_code, productData?.glass_size, productData?.isPolarised?.value])

    const getProductId = () => {
        let tempBrandID = productData?.brand_id?.code ? productData.brand_id.code : '';
        let tempProductType = productData?.product_type_id?.code ? productData.product_type_id.code : '';
        let tempModalNumber = productData?.modal_number ? productData.modal_number : '';
        let tempColorCode = productData?.brand_color_code ? productData.brand_color_code : '';
        let temSize = productData?.glass_size ? productData?.glass_size?.value : '';
        let temPolorised = productData?.isPolarised?.value === 1 ? "P" : '';
        let tempProductId = ([tempBrandID, tempProductType, tempModalNumber, tempColorCode, temSize, temPolorised].filter(Boolean).join('_')).toUpperCase();
        setProductData((prevState: any) => ({ ...prevState, product_id_display: tempProductId }));
    }

    const getDescription = () => {
        let tempProductType = productData?.product_type_id?.label ? productData.product_type_id.label.toUpperCase() : '';
        let tempBrandID = productData?.brand_id?.label ? productData.brand_id.label : '';
        let tempProductRimType = productData?.rim_type?.label ? productData.rim_type.label : '';
        // let tempColorCode = productData?.specx_colors || [];
        let tempColorCode = productData?.brand_color_name || [];
        // const colorCodeLabels = tempColorCode.map((color: any) => color.label).join(' ');
        const colorCodeLabels = tempColorCode;
        let tempProductShape = productData?.shapes || [];
        const productShapeLabels = tempProductShape.map((shape: any) => shape.label).join(' ');
        let temPolorised = productData?.isPolarised?.value === 1 ? 'POLARIZED' : '';
        let tempDescription = [tempBrandID, colorCodeLabels?.length > 0 ? colorCodeLabels + ' ' + 'COLOR' : '', productShapeLabels, tempProductRimType, temPolorised, tempProductType,];
        // let tempDescription = [tempProductType, tempBrandID,tempColorCode, tempProductRimType, productShapeLabels,];
        tempDescription = tempDescription.filter(value => value && value.trim() !== '')

        if (productData?.productDescription?.split(',')?.length > 1) {
            const responseDescription = productData?.productDescription?.split(',')?.slice(1)?.join();
            const description = `${tempDescription.join(' ')} ${responseDescription}`
            setProductData((prevState: any) => ({ ...prevState, description }));
        } else {
            const description = tempDescription.join(' ');
            setProductData((prevState: any) => ({ ...prevState, description }));
        }
    }

    return (
        <div>
            <div className='row align-items-center mb-4'>
                <div className="col-6">
                    <TopBar heading='Add' />
                </div>

                <div className=' col-6 d-flex justify-content-end align-item-end gap-3 align-items-center'>
                    <h2 className='mb-0 fs-18 fw-700 secondary-color '>Product ID:</h2>
                    <div className='d-flex w-auto justify-content-between align-items-center p-2 add-product-custom-div cursor-pointer' style={{minWidth:"13rem"}}>
                        <h2 className='mb-0 fs-16 fw-600 secondary-color'>{productData.product_id_display}</h2>
                        <div className='ms-2'>
                            <Image src={CopyIcon} onClick={() => productData.product_id_display && navigator.clipboard.writeText(productData.product_id_display)} />
                        </div>
                    </div>

                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-4'>
                    <Select label={"Brand"} options={brand} className={"w-100"} placeholder={"Select Brand"} value={productData.brand_id}
                        onChange={(e: any) => { handleChange("brand_id", e), handleBrand(e) }} errors={errors.brand_id}
                    />
                </div>
                <div className='col-4'>
                    <Select errors={errors.product_type_id} label={"Product Type"} options={productType} className={"w-100"} placeholder={"Select Type"}
                        value={productData.product_type_id || ''} onChange={(e: any) => { handleChange("product_type_id", e) }}
                    />
                </div>
                <div className='col-4'>
                    <Input errors={errors.modal_number} label={"Model Number"} type={'text'} placeholder={"Enter Model Number"} value={productData.modal_number} onChange={(e: any) => { handleChange("modal_number", e.target.value) }} />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-4'>
                    <Input errors={errors.brand_color_code} label={"Brand Color Code"} placeholder={"Enter Color Code"} value={productData.brand_color_code} onChange={(e: any) => {
                        handleChange("brand_color_code", e.target.value)
                    }} />
                </div>
                <div className='col-4'>
                    <Input errors={errors.brand_color_name} label={"Brand Color Name"} placeholder={"Enter Color Name"} value={productData.brand_color_name} onChange={(e: any) => { handleChange("brand_color_name", e.target.value) }} />
                </div>
                <div className='col-4'>
                    <Select
                        errors={errors.specx_colors}
                        label={"SW Color Name"}
                        placeholder={"Enter Color Name"}
                        className={"w-100"}
                        options={productColor}
                        isMulti={true}
                        value={productData.specx_colors}
                        onChange={(e: any) => { handleChange("specx_colors", e) }} />
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-4'>
                    <Select errors={errors.lens_colors} label={"SW Lens Color"} placeholder={"Enter Lens Color"} className={"w-100"} options={productColor} isMulti={true} value={productData.lens_colors}
                        onChange={(e: any) => { handleChange("lens_colors", e) }} />
                </div>
                <div className='col-4'>
                    <Select
                        errors={errors.glass_size}
                        label={"Glass Size"}
                        placeholder={"Enter Glass Size"}
                        className={"w-100"}
                        options={glassSizes}
                        value={productData.glass_size}
                        onChange={(e: any) => { handleChange("glass_size", e) }} />
                </div>
                <div className='col-4'>
                    <Select
                        errors={errors.temple_size}
                        label={"Temple Size"}
                        placeholder={"Enter Temple Size"}
                        className={"w-100"}
                        options={templeSizes}
                        value={productData.temple_size}
                        onChange={(e: any) => { handleChange("temple_size", e) }} />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-4'>
                    <Select
                        errors={errors.bridge_size}
                        label={"Bridge Size"}
                        placeholder={"Enter Bridge Size"}
                        className={"w-100"}
                        options={bridgeSizes}
                        value={productData.bridge_size}
                        onChange={(e: any) => { handleChange("bridge_size", e) }} />
                </div>
                <div className='col-4 '>
                    <Select label={"Product Material"} errors={errors.product_materials} options={productMaterial} className={"w-100"} placeholder={"Select Material"} isMulti={true} value={productData.product_materials}
                        onChange={(e: any) => { handleChange("product_materials", e) }} />
                </div>
                <div className='col-4'>
                    <Select errors={errors.features} label={"Product Features"} className={"w-100"} options={constant.PRODUCT_FEATURES} placeholder={"Select Features"} isMulti={true} value={productData.features} onChange={(e: any) => {
                        handleChange("features", e)

                    }} />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-4'>
                    <Select label={"Rim Type"} errors={errors.rim_type} className={"w-100"} options={constant.PRODUCT_RIM_TYPE} value={productData.rim_type} placeholder={"Select Rim"}
                        onChange={(e: any) => { handleChange("rim_type", e) }} />
                </div>
                <div className='col-4'>
                    <Select label={"Product Shape"} isMulti={true} errors={errors.shapes} options={productShape} className={"w-100"} placeholder={"Select Shape"} value={productData.shapes}
                        onChange={(e: any) => { handleChange("shapes", e) }} />
                </div>
                <div className='col-4'>
                    <Select label={"Gender"} className={"w-100"} errors={errors.gender} isMulti={true} options={constant.GENDER} placeholder={"Select Gender"} value={productData.gender}
                        onChange={(e: any) => { handleChange("gender", e) }} />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-4'>
                    <Input label={"Product SRP"} placeholder={"Enter SRP"} errors={errors.product_srp} value={productData.product_srp}
                        onChange={(e: any) => { handleChange("product_srp", e.target.value) }} />
                </div>
                <div className='col-4'>
                    <Select label={"Warranty"} errors={errors.warranty} className={"w-100"} placeholder={"Select Warranty"} options={constant.PRODUCT_WARRANTY}
                        value={productData.warranty} onChange={(e: any) => { handleChange("warranty", e) }} />
                </div>
                <div className='col-4'>
                    <Select label={"Product Weight"} errors={errors.weight} options={constant.PRODUCT_WEIGHT} className={"w-100"} placeholder={"Enter Weight"}
                        value={productData.weight} onChange={(e: any) => { handleChange("weight", e) }} />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-4 '>
                    <label className='inputs-label mb-1 fs-16 fw-600  secondary-color ' htmlFor='true'>Product With Case</label>
                    <form className='d-flex gap-3'>
                        <RadioInput type="radio" className={"form-check-input"} checked={productData.product_with_case === true && true} label={"Yes"} onChange={(e: any) => { setProductData({ ...productData, product_with_case: true }) }} id={'Yes'} />
                        <RadioInput type="radio" className={"form-check-input"} checked={productData.product_with_case === false && true} label={"No"} onChange={(e: any) => { setProductData({ ...productData, product_with_case: false }) }} id={'No'} />
                    </form>
                </div>
                <div className='col-4'>
                    <Select label={"Polarized"} className={"w-100"} errors={errors.isPolarised} placeholder={"Select Polarized"} options={constant.POLARIZED}
                        value={productData.isPolarised} onChange={(e: any) => { handleChange("isPolarised", e) }}
                    />
                </div>
                <div className='col-4'>
                    <div className=" px-2">
                        <Input className="form-control px-4 py-3" type="file" id="formFile"
                            ref={fileInputRef}
                            errors={errors.images}
                            onChange={(e: any) => {
                                _handleImageChange(e)
                            }}
                            label={"Upload Images"}
                            accept={"image/png, image/gif, image/jpeg"}
                            multiple={true}
                            placeholder={"formate :- JPG,JPGE,PNG"} />
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <div className="d-flex row">
                    {previewImage && previewImage.map((image: any, index) => {
                        return (
                            <div className="photo_upload mb-2 m-3 col-2" onClick={() => _handelCurrentCover(index)}>
                                <div className="photo_preview cursor-pointer mb-2">
                                    <div
                                        id="imagePreview"
                                    >
                                        <Image src={`${image && image}`} alt='aaa' className={"img-fluid preview_image"} />

                                    </div>
                                </div>
                                <div className='align-items-center d-flex delete-icon justify-content-center'
                                    onClick={() => { handleImageRemove(image, index) }}
                                >
                                    <Image src={CloseIcon} />
                                </div>
                                {productData.cover_index === index && <div className='align-items-center bottom-0 position-absolute text-center w-100 cover-image'>
                                    <p className='cover-text fs-12 fw-500 my-3'>Cover Image</p>
                                </div>}
                            </div>
                        )
                    })
                    }
                </div>

            </div>
            <div className='mt-3'>
                <Input label={"Product Description"} value={productData.description}
                    onChange={(e: any) => { handleChange("description", e.target.value) }}
                    className='text-capitalize'
                    placeholder='Enter Product Description' errors={errors.description} />

            </div>
            <div className='justify-content-center d-flex mt-4'>
                <Button name={"Continue"} className={"w-25 m-4"} 
                onClick={() => { handleSubmit() }} />
            </div>
        </div>
    )

}
export default Index