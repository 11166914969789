import React from 'react'
import { numberWithCommas } from '../../../constants/utils';

const Index = ({ inventoryDetails }: any) => {

    const sumOfNumbers = (arr: any, key: string, subKey: string) => {
        let sum = 0;
        for (let i = 0; i < arr?.length; i++) {
            sum += arr[i][key] ? arr[i][key][subKey] : 0;
            // console.log('arr', sum)
        }
        return sum;
    }

    return (
        <>
            <div className="container mt-3" style={{overflow:"scroll"}}>
                <table className="table table-bordered text-center">
                    <thead>
                        <tr>
                            <th rowSpan={3}>S.No.</th>
                            <th rowSpan={3}>Brand Name</th>
                            <th colSpan={2}>Total Store Inventory</th>
                            <th colSpan={2}>Total Store Inventory</th>
                            <th colSpan={2}>Total Salable Inventory</th>
                        </tr>
                        <tr>
                            <th colSpan={2}>Store Ownership</th>
                            <th colSpan={2}>Store Database</th>
                            <th colSpan={2}>Specxweb Database</th>
                        </tr>
                        <tr>
                            <th>Qty</th>
                            <th>Landing Cost</th>
                            <th>Qty</th>
                            <th>Landing Cost</th>
                            <th>Qty</th>
                            <th>Landing Cost</th>
                        </tr>
                    </thead>
                    <tbody >
                        {inventoryDetails?.map((item: any, index: number) => {
                            return !(item.store_ownership.quantity === 0 && item.specx_ownership.quantity === 0 && item.specx_ownership_saleable.quantity === 0) ? (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.brand}</td>
                                    <td>{item.store_ownership.quantity}</td>
                                    <td>{numberWithCommas(parseFloat(Number(item.store_ownership.cost)?.toFixed(2)))}</td>
                                    <td>{item.specx_ownership.quantity}</td>
                                    <td>{numberWithCommas(parseFloat(Number(item.specx_ownership.cost)?.toFixed(2)))}</td>
                                    <td>{item.specx_ownership_saleable.quantity}</td>
                                    <td>{numberWithCommas(parseFloat(Number(item.specx_ownership_saleable.cost)?.toFixed(2)))}</td>
                                </tr>
                            ) : null
                        })}
                        <tr>
                            <td className='fw-700' colSpan={2}>Total</td>
                            <td className='fw-700'>{sumOfNumbers(inventoryDetails, 'store_ownership', 'quantity')}</td>
                            <td className='fw-700'>₹ {numberWithCommas(sumOfNumbers(inventoryDetails, 'store_ownership', 'cost'))}</td>
                            <td className='fw-700'>{sumOfNumbers(inventoryDetails, 'specx_ownership', 'quantity')}</td>
                            <td className='fw-700'>₹ {numberWithCommas(sumOfNumbers(inventoryDetails, 'specx_ownership', 'cost'))}</td>
                            <td className='fw-700'>{sumOfNumbers(inventoryDetails, 'specx_ownership_saleable', 'quantity')}</td>
                            <td className='fw-700'>₹ {numberWithCommas(sumOfNumbers(inventoryDetails, 'specx_ownership_saleable', 'cost'))}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Index