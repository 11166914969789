import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { inventory } = Dictionary

const invoiceDetail = {
  searchView: async (data: {}) => {
    return axios.post(BASE_URL + inventory.inventoryItems(), data)
  },
  brandView: async () => {
    return axios.get(BASE_URL + inventory.inventoryBrand())
  },
  edit: async (id: string, data: {}) => {
    return axios.put(BASE_URL + inventory.invoiceById(id), data)
  },
  add: async (data: {}) => {
    return axios.post(BASE_URL + inventory.inventoryInvoice(), data)
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + inventory.invoiceById(id))
  },
  addInventorySearch: async (search:any) => {
    return axios.get(BASE_URL + inventory.productSearch(), { params: search })
  },
  supplier: async () => {
    return axios.get(BASE_URL + inventory.supplier())
  },
  view: async (paramsObj: any) => {
    return axios.get(BASE_URL + inventory.inventory(), { params: paramsObj })
  },
}
export default invoiceDetail
