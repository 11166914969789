import React from "react";
import atoms from "../../../atoms";
import constant from "../../../../constants/constant";

const { Select, Input, Button } = atoms

const Index = ({ handleChange, handleSubmit, inventoryReportData }: any) => {

    const buttonValidation = (startDate: Date) => {
        const parsedDate1 = new Date(startDate);
        // const parsedDate2 = new Date(endDate);

        if (!parsedDate1 || isNaN(parsedDate1.getTime())) {
            return true;
        }
        // if (!parsedDate2 || isNaN(parsedDate2.getTime())) {
        //     return true;
        // }
        if(!inventoryReportData?.report_format){
            return true
        }
        return false
    }
    return (
        <>
            <div className="row">
                <div className="col-md-4 mb-3">
                    <Input label={"Report Date"}
                        placeholder={"Start Date"} type="date"
                        onChange={(e: any) => {
                            handleChange("report_date", e?.target?.value)
                        }}
                    />
                </div>
                {/* <div className="col-md-4 mb-3">
                    <Input label={"End Date"}
                        placeholder={"Enter Invoice Date"} type="date"
                        onChange={(e: any) => {
                            handleChange("created_to", e?.target?.value)
                        }}
                    />
                </div> */}
                <div className="col-md-4 mb-3">
                    <Select
                        className="w-100"
                        options={constant.DailyReportFormatForExcel}
                        onChange={(e: any) => handleChange("report_format", e.value)} placeholder={"Select"}
                        label={"Select Report Format"} />
                </div>
                <div className="col-md-4 mb-3"></div>
            </div>
            <div className="d-flex justify-content-center" style={{ marginTop: "10rem" }}>
                <Button isDisabled={buttonValidation(inventoryReportData?.report_date)} style={{ minWidth: "259px" }} onClick={() => handleSubmit()}>Submit</Button>
            </div>
        </>
    )
}

export default Index