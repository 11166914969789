import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import service from "../../../../api/services";
import { BASE_URL } from '../../../../constants/defaultValues'
import organisms from "../../../../components/organisms";
import { useToasts } from 'react-toast-notifications';
import { HeaderHeading } from "../../../../Context";
import { handleReportDownload } from "../../../../constants/utils"

const { InventoryReport } = organisms
const Index = () => {
    const { setHeader }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();

    useEffect(() => {
        setHeader("Reports - Inventory Report")
    }, [])

    const [inventoryReportData, setInventoryReport] = useState({
        report_date: Date,
        // created_to: Date,
        report_format: NaN,
    })

    const handleSubmit = async () => {
        try {
            const response = await service.reportService?.inventory({ report_date: inventoryReportData?.report_date })
            if (response.status === 200) {
                if (!response?.data?.data) {
                    addToast(response?.data?.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                } else {
                    const { report_format } = inventoryReportData || {};
                    handleReportDownload(response?.data?.data, "inventory_report", report_format, "");
                }
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }


    const handleChange = (name: string, value: any) => {
        setInventoryReport({ ...inventoryReportData, [name]: value })
    }


    return (
        <Wrapper>
            <InventoryReport
                handleChange={(name: string, value: any) => handleChange(name, value)}
                handleSubmit={() => handleSubmit()}
                inventoryReportData={inventoryReportData}
            />
        </Wrapper>
    )
}

export default Index