import React, { useState, useEffect, useRef } from 'react'
import atoms from '../../atoms'
import './ImagePopup.scss'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6"
import { RxCross2 } from "react-icons/rx";

const { Image } = atoms

const ImagePopup = ({ imageUrlData, handleClose, Index }: any) => {
    const scrollContainerRef = useRef<any>(null);
    const [current, setCurrent] = useState(0);
    const length = imageUrlData.length;
    const debounce: any = useRef(null);


    useEffect(() => {
        setCurrent(Index);
    }, [])

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(imageUrlData) || imageUrlData.length <= 0) {
        return null;
    }

    // const handleScroll = debounce(() => {
    //     const { scrollLeft, clientWidth, scrollWidth } = scrollContainerRef.current;
    //     const isEndOfScroll = scrollLeft + clientWidth >= scrollWidth - 10;

    //     if (isEndOfScroll) {
    //       nextSlide();
    //       scrollContainerRef.current.scrollLeft = 0;
    //     }
    //   }, 300); 
    const handleScroll = () => {
        clearTimeout(debounce.current);

        const { scrollLeft, clientWidth, scrollWidth } = scrollContainerRef.current;
        const isEndOfScroll = scrollLeft + clientWidth >= scrollWidth - 10;
        const isEndOfScrollRight = scrollLeft === 0;

        debounce.current = setTimeout(() => {
            if (isEndOfScroll) {
                nextSlide()
                scrollContainerRef.current.scrollLeft = 2;
            } else if (isEndOfScrollRight) {
                prevSlide()
                scrollContainerRef.current.scrollLeft = 1;
            }

        }, 500);
        return () => {
            clearTimeout(debounce.current);
        };
    }

    return (
        <>
            <div className="popup-main">
                <div className="popup-content" >
                    <section className='slider'>
                        <div className="position-absolute py-1 px-2 cross-icon"
                            onClick={handleClose}>
                            <RxCross2 className='cross-icon-size' style={{ color: '#222' }} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div className='d-flex'>
                                <div className='left-icon' onClick={prevSlide} >
                                    <FaChevronLeft className='mx-auto' onClick={prevSlide} />
                                </div>
                            </div>
                            <div className='d-flex image-height' style={{ overflowX: "scroll" }}
                                ref={scrollContainerRef} onScroll={() => handleScroll()}
                            >
                                {imageUrlData.map((slide, index) => {
                                    return (
                                        <div
                                            className={`slide-image-width slide border-0 m-auto ${index === current ? ' active' : 'd-none'}`}
                                            key={index}

                                        >
                                            {index === current && (
                                                <img
                                                    src={slide} alt='travel image' className='popup-image' />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            {/* style={{borderColor:(productDetails?.glass_size==item?.glass_size) ? "#32a1ce" : "black"}} */}
                            <div className='d-ld-flex'>
                                <div className='right-icon' onClick={prevSlide} >
                                    <FaChevronRight className='mx-auto' onClick={nextSlide} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </div >
        </>
    )
}

export default ImagePopup