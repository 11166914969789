import React from 'react'
import './Wrapper.scss'
const Wrapper = ({ children, className }: any) => {
    return (
        <div className="wrapper-layout" >
            <main className="wrapper-content">
                <div className={`${className ? className :"wrapper-card" } w-100`}>
                    {children}
                </div>
            </main>
        </div>
    )
}

export default Wrapper