import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";
import { HeaderHeading } from '../../../../Context'
import { EntriesState } from './interface'

const { Transaction } = organisms

const Index = () => {
    const [data, setData] = useState<EntriesState>({
        initialReload: false,
        listData: null,
        search: "",
        limitPerPage: Default_Page_Limit,
        currentPage: Default_Page_Number,
        created_to: "",
        created_from: ""
    })

    const { setHeader }: any = useContext(HeaderHeading);

    useEffect(() => {
        setHeader("Accounts - Transactions")
        handleChange("currentPage", Default_Page_Number)
        if (data?.currentPage == Default_Page_Number) {
            viewEntries()
        }
    }, [data?.limitPerPage])

    useEffect(() => {
        if (data?.initialReload) {
            viewEntries()
        } else {
            handleChange("initialReload", true)
        }
    }, [data?.search, data?.currentPage])

    useEffect(() => {
        if (data?.created_from && data?.created_to) {
            viewEntries()
        }
    }, [data?.created_from, data?.created_to])

    const viewEntries = async () => {
        try {
            const Response = await service?.accounts?.ViewEntries({
                "page": data?.currentPage,
                "limit": data?.limitPerPage,
                "search": data?.search,
                "created_to": data?.created_to,
                "created_from": data?.created_from,
            })
            if (Response?.status == 200) {
                handleChange("listData", Response?.data)
            }
        } catch (error) {
            console.log("error===>", error)
        }
    }

    const handleChange = (name: string, value: any) => {
        setData((prevState: any) => ({ ...prevState, [name]: value }));
    }

    return (
        <>
            <Wrapper>
                <Transaction
                    data={data}
                    handleChange={(name: string, value: any) => handleChange(name, value)}
                />
            </Wrapper>
        </>
    )
}

export default Index