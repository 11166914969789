import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import service from "../../../../api/services";
import {handleReportDownload} from "../../../../constants/utils"
import organisms from "../../../../components/organisms";
import { HeaderHeading } from "../../../../Context";
import { useToasts } from "react-toast-notifications";

const { DailyActivityReport } = organisms
const Index = () => {
    const { setHeader }: any = useContext(HeaderHeading);
    const { addToast }= useToasts();

    useEffect(() => {
        setHeader("Reports - Daily Activity Report")
    }, [])

    const [dailyActivityReportData, setDailyActivityReport] = useState({
        report_date: "",
        report_format:NaN,
    })
    // const [dailyActivityReportData, setDailyActivityReport] = useState({
    //     created_from: "",
    //     created_to: "",
    //     report_format: "",
    //     report_type: ""
    // })

    const DailyActivityReportDownload = async () => {
        // const payload = {
        //     created_from: dailyActivityReportData?.created_from,
        //     created_to: dailyActivityReportData?.created_to,
        //     report_format: dailyActivityReportData?.report_format,
        //     report_type: dailyActivityReportData?.report_type
        // }
        try {
            const response = await service.reportService.getDailyActivityReport({"report_date":dailyActivityReportData?.report_date})
            if (response.status === 200) {
                if (!response?.data?.data) {
                    addToast(response?.data?.message, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                } else {
                    const { report_format } = dailyActivityReportData || {};
                    handleReportDownload(response?.data?.data, "inventory_report", report_format, "");
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    }


    const handleChange = (name: string, value: any) => {
        setDailyActivityReport({ ...dailyActivityReportData, [name]: value })
    }


    return (
        <Wrapper>
            <DailyActivityReport
                handleChange={(name: string, value: any) => handleChange(name, value)}
                DailyActivityReportDownload={() => DailyActivityReportDownload()}
                dailyActivityReportData={dailyActivityReportData}
            />
        </Wrapper>
    )
}

export default Index