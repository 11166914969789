export const validationRules: any = {
    modal_number: {
        required: true,
    },
    brand_color_code: {
        required: true,
    },
    // brand_color_name: {
    //     required: true,
    // },

    glass_size: {
        required: true,
    },
 
    product_type_id: {
        "label": {
            required: true,
        },
    },
    brand_id: {
        "label": {
            required: true,
        },
    },
  
    isPolarised: {
        "label": {
            required: true,
        },
    },
    // images: { required: true },

};