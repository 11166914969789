import React, { useEffect, useState } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import molecules from '../../../components/molecules';
import organisms from '../../../components/organisms';
import service from '../../../api/services';
import { useParams } from 'react-router';
import constant from '../../../constants/constant';
import Images from '../../../assets/Images'

const { CarouselComp, TopBar } = molecules
const { ProductDetails } = organisms
const { Copy } = Images

const Index = () => {
  const params = useParams()
  const product_ID: string = params.id || ''
  const [productDetails, setProductDetails] = useState<any>()
  const [productID, setProductID] = useState<string>(product_ID)
  const [title, setTitle] = useState<string>('')
  const getProductDetails = async () => {
    try {
      const response = await service.product.productByID(productID)
      if (response.status === 200) {
        setProductDetails(response.data.data)
        getProductTitle(response.data.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const getProductTitle = (productDetails: any) => {
    let product = productDetails && productDetails
    let brand = product?.brand.brand_name
    let color = product?.specx_colors?.map((color: any) => color.color_name).join(' ')
    let shape = product?.shapes?.map((shape: any) => shape.name).join(' ')
    let tempRimType = constant.PRODUCT_RIM_TYPE.find((item: any) => {
      item.value === product?.rim_type
      return item
    })
    let rimType = tempRimType?.label
    let polarized = product?.isPolarised == 1 ? 'Polarised' : ''
    let productType = product?.product_type.name
    let tempDescription = [brand, color ? color + ' ' + 'color' : '', shape, rimType, polarized, productType];
    tempDescription = tempDescription.filter(value => value && value.trim() !== '')
    const tempTitle = tempDescription.join(' ');
    setTitle(tempTitle)
  }
  useEffect(() => {
    getProductDetails()
    window.scrollTo(0, 0);
  }, [productID])


  return (
    <Wrapper>
      <div className='row align-items-center mt-0'>
        <TopBar navigate={'/product/list'} >
          <div className='d-md-none d-flex align-items-center justify-content-end'>
            <h2 className='mb-md-0 mb-1 fs-md-16 fs-12 fw-600 d-flex text-end product-detail-heading-width'>Product ID :-</h2>
            <div className='id-input-design d-flex justify-content-between'>
              <h2 className='mb-0 fs-md-16 fs-12 fw-400'>{productDetails?.product_id_display}</h2>
              <img src={Copy} alt="" className='img-fluid' />
            </div>
          </div>
        </TopBar>
      </div>
      <div className='row mt-md-4 mt-2'>
        <div className="col">
          <CarouselComp productDetails={productDetails} />
        </div>
      </div>
      <ProductDetails productDetails={productDetails} title={title} _handleProductId={(e: any) => { setProductID(e) }} />
    </Wrapper>
  )
}

export default Index