import BackIcon from './backIcon.svg';
import Coin from './coin.svg';
import Currency from './currency.svg';
import coverImage from './coverImage.png';
import CoverImage from './coverImage.png';
import Filter from './filter.svg';
import Copy from './copy.svg';
import LoginImage from './specx_logo.jpg';

const Images = {
    BackIcon,
    Coin,
    Currency,
    coverImage,
    Filter,
    CoverImage,
    Copy,
    LoginImage,
}
export default Images