import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { Default_Page_Limit } from "../../../../constants/defaultValues"
import { pageInterface } from './interface';
import { HeaderHeading } from '../../../../Context'

const { OrderHistory } = organisms

const Index = () => {
  const [initialLoading, setInitialLoading]=useState(false)
  const [orderHistory, setOrderHistory] = useState()
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();
  const [page, setPage] = useState<pageInterface>({
    totalPages: 1,
    totalCount: 1
  })
  const { setHeader }: any = useContext(HeaderHeading);

  useEffect(() => {
    setHeader("Procurement - Order History")
    if(initialLoading){
      orderHistoryList()
    }else{
      setInitialLoading(true)
    }
  }, [search, currentPage])

  useEffect(() => {
    setCurrentPage(1)
    if (currentPage == 1) {
      orderHistoryList()
    }
  }, [limitPerPage])

  const orderHistoryList = async () => {
    try {
      const response = await service.procurementService.viewOrderHistory({ "page": currentPage, "search": search, "limit": limitPerPage, "status": 4 })
      if (response.status === 200) {
        let data: any = response?.data?.data
        setPage({ ...page, totalPages: response?.data?.totalPages, totalCount: response?.data?.count })
        data.forEach((items: any) => {
          const groupedData: any = [];
          items.procurement_items.forEach((element: any) => {
            if (groupedData[element.status]) {
              groupedData[element.status].push(element)
            } else {
              groupedData[element.status] = []
              groupedData[element.status].push(element)
            }
          })
          items.groupData = groupedData
        })
        setOrderHistory(data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <Wrapper>
      <OrderHistory
        orderHistory={orderHistory}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setLimitPerPage={setLimitPerPage}
        count={page.totalCount}
        totalPages={page.totalPages}
        limit={limitPerPage}
      />
    </Wrapper>
  )
}

export default Index