const Product = {
    product: () => 'inventory/stocks/v2',
    filter: () => 'inventory/filters',
    productById: (id: string) => `inventory/stocks/v2/${id}`,
    productTypeList: () => `admin/product-type`,
    addToCart: () => 'store/cart/add',

    // Add Product
    addProduct: () => 'store/product',
    deleteProductById: (id: string) => `admin/product/${id}`,
    productImages: () => `admin/product/images`,
    productShape: () => 'admin/product-shape',
    productMaterial: () => 'admin/product-material',
    productColor: () => 'admin/color-code',
    brand: () => 'store/brands-product-types',
    sizeDetails: () => 'admin/size-details',

}
export default Product
