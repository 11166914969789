import { useContext, useState, useEffect } from 'react'
import "./Sidebar.scss"
import Menu from '../../../assets/Images/menu.svg'
import Atoms from '../../atoms'
import { NavLink } from 'react-router-dom'
import { BsArrowLeftRight, BsPerson } from 'react-icons/bs'
import { BiCart, } from "react-icons/bi";
import { NotificationCount } from '../../../Context'
import { AiOutlineBarChart } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import Images from '../../../assets/Images';
import constant from '../../../constants/constant'
import { RxDashboard } from 'react-icons/rx'

const { Image, Badges } = Atoms
const SideBar = () => {
  const navigate = useNavigate()
  const [notificationCount, setNotificationCount] = useContext<any>(NotificationCount);
  const [showAccounts, setShowAccounts] = useState<string>("")

  useEffect(() => {
    const urlSegments = window.location.href?.split('/').slice(0, 3);
    const derivedUrl = urlSegments?.length >= 3 ? urlSegments.join('/') : constant?.dev_URL;
    setShowAccounts(derivedUrl);
  }, []);

  return (
    <div>
      <div className="s-sidebar nav-scroll">
        <div className="s-layout__sidebar " style={{ position: "relative" }}>
          {/* <a className="s-sidebar__trigger" href="#0" > */}
          <button className="navbar-toggler shadow-none d-lg-none nav-toggle-icon-style"
            // style={{ height: "60px", position: "static", left: "1px", zIndex: 5 }}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#s-sidebar__nav"
            aria-controls="s-sidebar__nav"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <Image className='fs-16  border p-1 m-1' src={Menu} />
          </button>
          {/* </a> */}

          <nav className={`s-sidebar__nav ${window.innerWidth < 876 && "collapse"}`} id="s-sidebar__nav"
            style={{ position: "absolute", top: 0, left: 0, zIndex: 10005, width: "15em", height: "100%" }}>
            <ul style={{ marginBottom: "4rem" }}>
              <div className="w-100 text-center"
                data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                data-bs-target="#s-sidebar__nav"
                aria-controls="s-sidebar__nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => navigate('/dashboard')}>
                {/* <Image src={Logo} className='img-fluid logo' /> */}
                <Image src={Images?.LoginImage} className='img-fluid logo' style={{ cursor: 'pointer', height: window?.innerWidth < 770 && "76px" }} />

              </div>
              <div className="mt-25"></div>
              <li className="">
                <NavLink
                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color"
                  data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                  data-bs-target="#s-sidebar__nav"
                  aria-controls="s-sidebar__nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => navigate("/brand")}
                  to="/brand">
                  <span><AiOutlineBarChart className='fs-18' /></span>Brand
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color"
                  data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                  data-bs-target="#s-sidebar__nav"
                  aria-controls="s-sidebar__nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => navigate("/add-product")}
                  to="/add-product">
                  <span><AiOutlineBarChart className='fs-18' /></span>Add Product
                </NavLink>
              </li>
              {/* {
                <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                  to="/procurement/add-product"
                  data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                  data-bs-target="#s-sidebar__nav"
                  aria-controls="s-sidebar__nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => navigate('/procurement/add-product')}
                >
                  <BsArrowLeftRight className='fs-20' />
                  <li className='sidebar-list-styled'>Add Product</li>
                </NavLink>} */}
              {/* <li className="">
                <NavLink
                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color"
                  data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                  data-bs-target="#s-sidebar__nav"
                  aria-controls="s-sidebar__nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => navigate("/inventory")}
                  to="/inventory"
                >
                  <span>
                    <AiOutlineBarChart className="fs-18" />
                  </span>
                  Inventory
                </NavLink>
              </li> */}

              {/* for Inventory */}

              <li className="">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="inventory_">
                      <button
                        className="position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#inventory"
                        aria-expanded="false"
                        aria-controls="inventory"
                      >
                        <BiCart className="fs-18" />
                        Inventory
                      </button>
                    </h2>
                    <div
                      id="inventory"
                      className="accordion-collapse collapse"
                      aria-labelledby="inventory_"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/inventory/approved"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                navigate("/inventory/approved")
                              }
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Approve Inventory
                              </li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/inventory/pending"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                navigate("/inventory/pending")
                              }
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Pending Inventory
                              </li>
                            </NavLink>
                          }
                                    {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/inventory/search"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                navigate("/inventory/search")
                              }
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Search
                              </li>
                            </NavLink>
                          }
                          {/* TODO Uncomment this code when Unrecognized branch work is finish */}
                          {/* {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/inventory/unrecognized"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                navigate("/inventory/unrecognized")
                              }
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Unrecognized Inventory
                              </li>
                            </NavLink>
                          } */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              {/* for Procurement */}
              <li className="">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="headingTwo">
                      <button
                        className="accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#procurement"
                        aria-expanded="false"
                        aria-controls="procurement"
                      >
                        <BiCart className='fs-18' />
                        Procurement<Badges count={notificationCount?.purchase_cart_count} />
                      </button>
                    </h2>
                    <div
                      id="procurement"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-0">
                        <ul
                          className="list-unstyled"
                        >
                          {
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/product/list"
                              data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() => navigate('/product/list')}
                            >
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Products</li>
                            </NavLink>}

                          {
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/order-cart"
                              data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() => navigate("/order-cart")}
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Order Cart{" "}
                                <Badges
                                  count={notificationCount?.purchase_cart_count}
                                />
                              </li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/procurement/order/history"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                navigate("/procurement/order/history")
                              }
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Order History
                              </li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/procurement/order/received"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                navigate("/procurement/order/received")
                              }
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Order Received
                              </li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/procurement/cancelled"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() => navigate("/procurement/cancelled")}
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Order Cancelled
                              </li>
                            </NavLink>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="accounts_">
                      <button
                        className="position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#requestedByOther"
                        aria-expanded="false"
                        aria-controls="requestedByOther"
                      >
                        <BiCart className="fs-18" />
                        Transfer Requests
                        {
                          <Badges
                            count={notificationCount?.other_req_count}
                            className="position-absolute top-2 right-16"
                          />
                        }
                      </button>
                    </h2>
                    <div
                      id="requestedByOther"
                      className="accordion-collapse collapse"
                      aria-labelledby="accounts_"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/transfer-request/requested"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                navigate("/transfer-request/requested")
                              }
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Requested{" "}
                                <Badges
                                  count={notificationCount?.other_req_count}
                                />
                              </li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/transfer-request/moved-out"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                navigate("/transfer-request/moved-out")
                              }
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">Moved Out</li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/transfer-request/cancelled"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                navigate("/transfer-request/cancelled")
                              }
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">Cancelled</li>
                            </NavLink>
                          }
                          {/*              
                          {
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/transfer-request/requested">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Requested <Badges count={notificationCount?.other_req_count} /></li>
                            </NavLink>} */}
                          {/* {
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/transfer-request/moved-out">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Moved Out</li>
                            </NavLink>} */}
                          {/* {
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/transfer-request/cancelled">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Cancelled</li>
                            </NavLink>} */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="">
                <NavLink
                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color"
                  to="/staff"
                  data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                  data-bs-target="#s-sidebar__nav"
                  aria-controls="s-sidebar__nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => navigate("/staff")}
                >
                  <span>
                    <BsPerson className="fs-18" />
                  </span>
                  Staff List
                </NavLink>
              </li>

              <li className="">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="reports_">
                      <button
                        className="position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#reports"
                        aria-expanded="false"
                        aria-controls="reports"
                      >
                        <BiCart className="fs-18" />
                        Reports
                      </button>
                    </h2>
                    <div
                      id="reports"
                      className="accordion-collapse collapse"
                      aria-labelledby="reports_"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/sales"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() => navigate("/report/sales")}
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Sales Report
                              </li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/sales-return"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() => navigate("/report/sales-return")}
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Sales Return Report
                              </li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/inventory"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() => navigate("/report/inventory")}
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Inventory Report
                              </li>
                            </NavLink>
                          }
                          {/*TODO show this daily activity report when daily activity report api complete */}
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/daily-activity"
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Daily Activity Report
                              </li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/gst"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() => navigate("/report/gst")}
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                GST Output Report
                              </li>
                            </NavLink>
                          }
                          {
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/report/inventory-addition"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() =>
                                navigate("/report/inventory-addition")
                              }
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Inventory Addition Report
                              </li>
                            </NavLink>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="">
                <NavLink
                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color"
                  to="/setting"
                  data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                  data-bs-target="#s-sidebar__nav"
                  aria-controls="s-sidebar__nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => navigate("/setting")}
                >
                  <span>
                    <RxDashboard className="fs-18" />
                  </span>
                  Setting
                </NavLink>
              </li>

              {/* Hide Account section from the portal, will be able to show again if required  */}
              {showAccounts === constant?.dev_URL ||
              showAccounts === constant?.uat_URL ? (
                <li className="">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item border-0">
                      <h2 className="accordion-header " id="accounts_">
                        <button
                          className="position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#accounts"
                          aria-expanded="false"
                          aria-controls="accounts"
                        >
                          <BiCart className="fs-18" />
                          Accounts
                        </button>
                      </h2>
                      <div
                        id="accounts"
                        className="accordion-collapse collapse"
                        aria-labelledby="accounts_"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0">
                          <ul className="list-unstyled">
                            {
                              <NavLink
                                className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                to="/accounts/transaction"
                                data-bs-toggle={
                                  window.innerWidth < 770 ? "offcanvas" : ""
                                }
                                data-bs-target="#s-sidebar__nav"
                                aria-controls="s-sidebar__nav"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={() =>
                                  navigate("/accounts/transaction")
                                }
                              >
                                <BsArrowLeftRight className="fs-20" />
                                <li className="sidebar-list-styled">
                                  Transaction
                                </li>
                              </NavLink>
                            }
                            {
                              <NavLink
                                className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                to="/accounts/general-account"
                                data-bs-toggle={
                                  window.innerWidth < 770 ? "offcanvas" : ""
                                }
                                data-bs-target="#s-sidebar__nav"
                                aria-controls="s-sidebar__nav"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={() =>
                                  navigate("/accounts/general-account")
                                }
                              >
                                <BsArrowLeftRight className="fs-20" />
                                <li className="sidebar-list-styled">
                                  General Accounts
                                </li>
                              </NavLink>
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}

              {/* <li className="">
                <div className="accordion" id="accordionAccounts">
                  <div className="accordion-item border-0">
                    <h2 className="accordion-header " id="accounts_">
                      <button
                        className="position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#_accounts"
                        aria-expanded="false"
                        aria-controls="_accounts"
                      >
                        <BiCart className='fs-18' />
                        Accounts
                        {<Badges count={notificationCount?.other_req_count} className="position-absolute top-2 start-40" />}
                      </button>
                    </h2>
                    <div
                      id="_accounts"
                      className="accordion-collapse"
                      aria-labelledby="accounts_"
                      data-bs-parent="#accordionAccounts"
                    >
                      <div className="accordion-body p-0">
                        <ul className="list-unstyled">
                          {
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/accounts/transaction">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>Transaction</li>
                            </NavLink>
                          }
                          {
                            <NavLink className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/accounts/general-account">
                              <BsArrowLeftRight className='fs-20' />
                              <li className='sidebar-list-styled'>General Accounts</li>
                            </NavLink>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
