import React from 'react'
import Button from '../Button'
import { InputInterface } from './interface'
import './Input.scss'
const Input = ({ id, type, name, disabled, defaultValue,
    onChange, errors, className, placeholder, label,
    htmlFor, accept, multiple, value, checked, required,
    ref, maxLength}: InputInterface) => {
    const handleKeyDown = (event: any) => {
        if (type === 'number' && (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'e' || event.key === '+' || event.key === '-')) {
            event.preventDefault();
        }
    }

    const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        if (type === 'number') {
            (event.target as HTMLInputElement).blur();
        }
    };
    return (
        <div className="form-group">
            {label && <label className='inputs-label mb-2 fs-16 fw-600  secondary-color' htmlFor={htmlFor}>{label}</label>}
            <input
                id={id}
                className={`${type === "radio" ? '' : "inputs form-control "} shadow-none ${className}`}
                type={type}
                name={name}
                defaultValue={defaultValue}
                onChange={onChange}
                placeholder={placeholder}
                accept={accept}
                multiple={multiple}
                value={value}
                checked={checked}
                required={required}
                ref={ref}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                maxLength={maxLength}
                onWheel={handleWheel}
            />
            {errors && <span className="error-text">{errors}</span>}
        </div>
    )
}

export default Input