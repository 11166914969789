import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Context, NotificationCount } from "../Context";
import { BASE_URL } from "../constants/defaultValues";
import constant from "../constants/constant";
import Auth from "./dictionary/auth";
let isRefreshToken = false;

const Interceptor = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [notificationCount, setNotificationCount] = useContext<any>(NotificationCount)
    const navigate = useNavigate();
    const [userPermissions, setUserPermissions] = useContext<any>(Context);
    const url = new URL(window.location.href);

    useEffect(() => {
        axios.interceptors.request.use((config) => {
            const AUTH_TOKEN = localStorage.getItem("access_token")
            let isLogin = (config && config.url && config.url.split("/")[config.url.split("/").length - 1] === 'login')
            if (config && config.headers && !(isLogin))
                config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
            if (!AUTH_TOKEN && !(isLogin)) {
                navigate("/login");
            }
            setIsLoading(true)
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(
            response => {
                setIsLoading(false);
                { response?.data?.notification_count && setNotificationCount(response?.data?.notification_count) }
                // logic for permission check
                const permissionsHeader = response?.headers['x-app-permission'];
                const result = [];
                for (let i = 0; i < permissionsHeader?.length; i += 5) {
                    result.push(permissionsHeader.slice(i, i + 5));
                }
                setUserPermissions(result)
                return response
            }, (error) => {
                setIsLoading(false)
                { error?.response?.data?.notification_count && setNotificationCount(error?.response?.data?.notification_count) }
                if (error.response && error.response.status === 401 && !isRefreshToken) {
                    const main = async () => {
                        try {
                            // localStorage.clear()
                            let tokens: any = await refreshToken();
                            axios.defaults.headers.common['Authorization'] = tokens.accessToken;
                            error.config.headers['Authorization'] = tokens.accessToken;
                            isRefreshToken = false
                            return axios.request(error.config);
                        } catch (err) {
                            isRefreshToken = false
                            localStorage.clear();
                            navigate("/login");
                        }
                    }
                    return main()
                } else {
                    return Promise.reject(error);
                }
            }
        )
    }, [])

    const refreshToken = () => {
        isRefreshToken = true
        return new Promise(async (resolve, reject) => {
            try {
                const refresh = localStorage.getItem('refresh_token')
                const res = await axios.post(BASE_URL + Auth.login(), {
                    grant_type: 'refresh_token',
                    refresh_token: refresh
                }, {
                    headers: {
                        'Authorization': 'Basic YXBwbGljYXRpb246c2VjcmV0',
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                localStorage.setItem("access_token", res.data.data.accessToken);
                localStorage.setItem("refresh_token", res.data.data.refreshToken)
                resolve(res.data.data)

            } catch (err) {
                reject(err);
            }
        })

    }
    return (
        <div>
            {((url?.pathname != constant?.PRODUCT_lIST_PATH) && isLoading) &&
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                        alignItems: 'center'
                    }}
                    className="loading-One"
                >
                    <div
                        style={{
                            position: 'fixed',
                            zIndex: '9999'
                        }}
                        className="spinner-border text-primary loading-Two" role="status">

                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
        </div >
    )
}
export default Interceptor;