import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import { Default_Page_Limit } from "../../../../constants/defaultValues"
import organisms from '../../../../components/organisms'
import { HeaderHeading } from '../../../../Context'

const { OrderCancelled } = organisms

const Index = () => {
  const [initialLoading, setInitialLoading] = useState(false)
  const [orderCancelledList, setOrderCancelledList] = useState<any>();
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();
  const { setHeader }: any = useContext(HeaderHeading);


  useEffect(() => {
    setHeader("Procurement - Order Cancelled")
    if (initialLoading) {
      OrderCancelledList()
    }
      setInitialLoading(true)
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(1)
    if (currentPage == 1) {
      OrderCancelledList()
    }
  }, [limitPerPage,search])

  const OrderCancelledList = async () => {
    try {
      const response = await service.procurementService.OrderCancelledList({ "page": currentPage, "search": search, "limit": limitPerPage, "status": "" })
      if (response.status === 200) {
        setOrderCancelledList(response?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <Wrapper>
      <OrderCancelled
        orderCancelledList={orderCancelledList}
        setSearch={setSearch}
        limit={limitPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setLimitPerPage={setLimitPerPage}
      />
    </Wrapper>
  )
}

export default Index