import auth from './auth'
import staff from './staff'
import PurchasedCard from './OrderCard'
import Procurement from './procurement'
import product from './product'
import inventory from './inventory'
import accounts from './accounts'
import store from './store'
import salesOrder from './salesOrder'
import report from './report'
import brand from './brand'
import dashboard from './dashboard'
import settingTab from './setting'

const dictionary = {
  auth,
  staff,
  PurchasedCard,
  Procurement,
  product,
  inventory,
  accounts,
  store,
  salesOrder,
  report,
  brand,
  dashboard,
  settingTab
}
export default dictionary
