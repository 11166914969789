import React from "react";
import atoms from "../../atoms";
import constant from "../../../constants/constant";

const { Select, Input, Button } = atoms

const Index = ({ handleChange, handleSubmit, reportData, glAccountList }: any) => {

    const buttonDisable = (startDate: Date, endDate: Date) => {
        const parsedDate1 = new Date(startDate);
        const parsedDate2 = new Date(endDate);

        if (!parsedDate1 || isNaN(parsedDate1.getTime())) {
            return true;
        }
        if (!parsedDate2 || isNaN(parsedDate2.getTime())) {
            return true;
        }
        if (!reportData?.report_format) {
            return true
        }

        if (!reportData?.gl_account_number?.value) {
            return true
        }
        return false
    }
    return (
        <>
            <div className="row mt-3">
                <div className="col-md-4">
                    <Select
                        className="w-100"
                        options={glAccountList}
                        value={reportData?.gl_account_number}
                        onChange={(e: any) => handleChange("gl_account_number", e)} placeholder={"Select"}
                        label={"Select GL Account Number"} />
                </div>
                <div className="col-md-4 mt-3 mt-md-0">
                    <Select
                        className="w-100"
                        options={constant.DailyReportFormat}
                        onChange={(e: any) => handleChange("report_format", e.value)} placeholder={"Select"}
                        label={"Select Report Format"} />
                </div>
                <div className="col-md-4"></div>

            </div>
            <div className="row mt-4">
                <div className="col-6 col-md-4">
                    <Input label={"Start Date"}
                        placeholder={"Start Date"} type="date"
                        onChange={(e: any) => {
                            handleChange("created_from", e.target.value)
                        }}
                    />
                </div>
                <div className="col-6 col-md-4">
                    <Input label={"End Date"}
                        placeholder={"Enter Invoice Date"} type="date"
                        onChange={(e: any) => {
                            handleChange("created_to", e.target.value)
                        }}
                    />
                </div>
                <div className="col-md-4"></div>

            </div>
            <div className="d-flex justify-content-center" style={{ marginTop:window?.innerWidth>768 ? "10rem" : "5rem" }}>
                <Button
                    isDisabled={buttonDisable(reportData?.created_from, reportData?.created_to)}
                    style={{ minWidth: "259px" }}
                    onClick={() => handleSubmit()}
                >Submit</Button>
            </div>
        </>
    )
}

export default Index