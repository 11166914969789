import React from "react";
import { indexProps } from "./interface"

const Index: React.FC<indexProps> = ({
  printPrescription,
  updateSetting,
}) => {
  return (
    <div className="d-flex">
      <div className="card px-5 py-3">
        <span className="fs-22 fw-600">Print Prescription</span>
        <div className="d-flex gap-4 mt-3">
          <label
            className="inputs-label fs-20 fw-600"
            htmlFor={"isPictures"}
          >
            <div
              className="form-group d-flex align-items-center ms-1 gap-3"
              style={{ minHeight: "36px" }}
            >
              <input
                className={`form-check-input shadow-none mt-0`}
                type="checkbox"
                id={"isPictures"}
                name={"isPictures"}
                checked={printPrescription}
                onChange={(e) => !printPrescription && updateSetting()}
              />
              <span>Yes</span>
            </div>
          </label>
          <label
            className="inputs-label fs-20 fw-600"
            htmlFor={"isPictures"}
          >
            <div
              className="form-group d-flex align-items-center ms-1 gap-3"
              style={{ minHeight: "36px" }}
            >
              <input
                className={`form-check-input shadow-none mt-0`}
                type="checkbox"
                id={"isPictures"}
                name={"isPictures"}
                checked={!printPrescription}
                onChange={(e) => printPrescription && updateSetting()}
              />
              <span>No</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Index;
