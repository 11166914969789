import React from 'react'
import atoms from '../../atoms';
import Images from '../../../assets/Images';
import { useNavigate } from 'react-router';
const { Image } = atoms
const { BackIcon } = Images
const TopBar = ({ heading, children }: any) => {
    const navigate = useNavigate()
    return (
        <div>
            <div className='d-flex align-items-center gap-2'>
                <Image src={BackIcon} alt="Back Icon" className='img-fluid cursor-pointer back-icon-width' onClick={() => navigate(-1)} />
                <h2 className='mb-0 fs-md-20 fs-16 fw-600'>{heading}</h2>
                <div className='fs-md-18 fs-16 fw-600'>{children}</div>
            </div>
        </div>
    )
}

export default TopBar