import { useState, useContext, useEffect } from 'react'
import User from '../../../assets/Images/user.svg';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import "./Header.scss"
import Atoms from '../../atoms'
import service from '../../../api/services';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router';
import { TbLogout } from "react-icons/tb";
import { HeaderHeading, StorePopup } from '../../../Context'
import { BiStore } from 'react-icons/bi';
import { CiUser } from "react-icons/ci";
import { Tooltip } from 'react-tooltip'

interface HeaderProps {
  storeList: { value: string; label: string }[];
}

const { Image } = Atoms
const Header: React.FC<HeaderProps> = ({ storeList }) => {
  const { addToast } = useToasts();
  const { header }: any = useContext(HeaderHeading);
  const navigate = useNavigate()
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const [isShow, setIsShow] = useContext<any>(StorePopup);
  const token = localStorage.getItem("access_token")

  const logout = async () => {
    try {
      let response = await service.auth.logout(token)
      if (response.status === 200) {
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        localStorage.setItem("access_token", '')
        localStorage.setItem("username", '')
        localStorage.setItem("store_name", '')

        navigate("/login");
      }
    } catch (error: any) {
      console.log(error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }
  return (
    <div className="s-layout">
      <main className="s-layout__content header-bg justify-content-between">
        <h3 className="mb-0 mx-lg-0 mx-3 fs-24 font-size-12 fw-700"> {header}</h3>
        <div className="me-lg-4 d-flex gap-2 ">
          {/* <Image src={User} className={"btn"}/> */}
          <div className='d-flex justify-content-end m-auto color-black fs-14 d-md-block d-none fw-500'>
            {/* <div className='d-flex justify-content-end' >
              {localStorage.getItem("store_name")}
            </div> */}
            <div className='d-flex justify-content-end'>
              {localStorage.getItem("username")}
            </div>

            {
              localStorage.getItem("store_location") !== null ?
                <div className='text-truncate' style={{ maxWidth: "210px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={localStorage.getItem("store_location")}>
                  {/* Get stored store_address value from localStorage to show in header */}
                  {localStorage.getItem("store_location") !=='undefined' ? localStorage.getItem("store_location")  : null}
                  <Tooltip id="my-tooltip" place='bottom-start' variant='dark' />
                </div>
                : ""
            }
          </div>
          <Dropdown group isOpen={toggleDropdown} size="lg" toggle={() => setToggleDropdown(!toggleDropdown)}>
            <DropdownToggle className='dropdown-button-hide cursor-pointer'>
              <Image className='header-user-icon-width' src={User}/>
              {/* <div className={`nav-link p-0 style-class position-relative cursor-pointer`}
                style={{
                  border: '1px solid #a9a9a9',
                  borderRadius: '50%',
                }}
              >
                <div className='nav-user-icon'><CiUser /></div>
              </div> */}
            </DropdownToggle>
            <DropdownMenu style={{ minWidth: '12rem' }}>
              {storeList.length > 1 && (
                <div className='p-0 d-flex header-dropdown align-items-center gap-1 ps-3'>
                  <BiStore />
                  <span className='cursor-pointer  p-2' onClick={() => setIsShow(true)}>Select Store</span>
                </div>
              )}
              <div className='p-0 d-flex header-dropdown align-items-center gap-1 ps-3'>
                <TbLogout />
                <span className='cursor-pointer  p-2' onClick={() => navigate('/change-password')}>Change password</span>
              </div>
              <div className='p-0 d-flex header-dropdown align-items-center gap-1 ps-3'>
                <TbLogout />
                <span className='cursor-pointer  p-2' onClick={() => logout()}>Logout</span>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </main>
    </div>
  )
}

export default Header

